import Vue from "vue";
import VueRouter from "vue-router";
import AllProducts from "../views/AllProducts.vue";
import CartEmpty from "../views/CartEmpty.vue";
import CartSummary from "../views/CartSummary.vue";
import Checkout from "../views/Checkout.vue";
import ConfirmPayment from "../views/ConfirmPayment.vue";
import Home from "../views/Home.vue";
import Payment from "../views/Payment.vue";
import Product from "../views/Product.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/all-products",
    name: "AllProducts",
    component: AllProducts,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/cart-summary",
    name: "CartSummary",
    component: CartSummary,
  },
  {
    path: "/cart-empty",
    name: "CartEmpty",
    component: CartEmpty,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/confirm-payment",
    name: "ConfirmPayment",
    component: ConfirmPayment,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
