<template>
  <div>
    <template>
      <v-card class="pa-4" v-if="!isForgetPassword">
        <!-- <div class="text-right">
          <v-btn @click="$emit('close')" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div> -->
        <div class="mt-2 mb-4 text-center">
          <h2>เข้าสู่ระบบ</h2>
        </div>
        <div>
          <ValidationObserver ref="observer">
            <div>
              <label class="colabel">
                อีเมล:
                <span style="color: red">*</span>
              </label>
              <ValidationProvider
                name="username"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="input.username"
                  color="black"
                  outlined
                  :error-messages="errors"
                  dense
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div>
              <label class="colabel">
                รหัสผ่าน:
                <span style="color: red">*</span>
              </label>
              <ValidationProvider
                name="password"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="input.password"
                  type="password"
                  color="black"
                  :error-messages="errors"
                  outlined
                  dense
                ></v-text-field>
              </ValidationProvider>
            </div>
          </ValidationObserver>
        </div>
        <div class="text-center">
          <v-btn
            style="
              font-weight: 600;
              font-size: 20px;
              color: white;
              height: 48px;
            "
            :loading="saveLoading"
            dark
            block
            @click="onLogin"
            >ยืนยัน</v-btn
          >
        </div>
        <div class="text-center">
          <v-btn @click="onForgetPassword" text ripple>
            <span>ลืมรหัสผ่าน</span>
          </v-btn>
        </div>
        <div class="mt-4">
          <v-layout wrap>
            <v-flex xs6 class="pr-2">
              <v-btn
                @click="onLoginAsGuest"
                dark
                style="width: 100%; height: 48px"
              >
                <span>สั่งซื้อเลย</span>
              </v-btn>
            </v-flex>
            <v-flex xs6 class="pl-2">
              <v-btn
                @click="$emit('onRegister')"
                dark
                style="width: 100%; height: 48px"
              >
                <span>สมัครสมาชิก</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-card>
      <v-card v-else class="pa-4">
        <template v-if="!statusSentEmail">
          <div class="mt-2 mb-4">
            <h2>ลืมรหัสผ่าน</h2>
          </div>
          <ValidationObserver ref="observerForgetPassword">
            <div>
              <label class="colabel">
                อีเมล:
                <span style="color: red">*</span>
              </label>
              <ValidationProvider
                name="email"
                :rules="{
                  required: true,
                  email: true,
                }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="email"
                  color="black"
                  outlined
                  :error-messages="errors"
                  dense
                ></v-text-field>
              </ValidationProvider>
            </div>
          </ValidationObserver>
          <div class="text-center">
            <v-btn
              style="font-size: 16px; color: white; height: 48px"
              :loading="saveLoading"
              dark
              block
              @click="sendForgetPassword"
              >ยืนยัน</v-btn
            >
            <v-btn
              class="mt-2"
              style="font-size: 14px"
              text
              ripple
              @click="isForgetPassword = false"
              >ย้อนกลับ</v-btn
            >
          </div>
        </template>
        <template v-else>
          <div class="mt-3">
            <div class="text-center">
              <v-avatar size="62" color="#FFF" style="border: 4px solid #000">
                <v-icon dark x-large color="black"> mdi-check </v-icon>
              </v-avatar>
            </div>
            <div class="my-6 text-center">
              If there is an account associated with <b>{{ email }}</b
              ><br />
              You will receive an email with a link to reset your password.
            </div>
          </div>
          <div class="mb-3 text-center">
            <v-btn
              style="font-size: 16px; color: white; height: 48px; width: 150px"
              :loading="saveLoading"
              dark
              @click="$emit('close')"
              >ตกลง</v-btn
            >
          </div>
        </template>
      </v-card>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import queryString from "query-string";
const saltedMd5 = require("salted-md5");

export default {
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      input: {
        username: "",
        password: "",
      },
      email: "",
      saveLoading: false,
      isForgetPassword: false,
      statusSentEmail: false,
    };
  },
  computed: {
    baseAPI() {
      return this.$store.state.oandbBaseAPI;
    },
  },
  methods: {
    async onLogin() {
      if (await this.$refs.observer.validate()) {
        var vm = this;
        var data = {
          email: vm.input.username,
          password: saltedMd5(vm.input.password, "BYhZ6Y942Myn"),
          userid: vm.$store.state.userid,
          pageid: vm.$route.query.pageid,
        };

        vm.saveLoading = true;
        axios
          .post(vm.serverUrl + "v1/oab/customer/login", data, {
            headers: {
              AUTHORIZATION: "Bearer xxx xxx",
            },
          })
          .then(async (response) => {
            if (response.data.responsecode == 200) {
              var customerId = response.data.data.data.code;
              await vm.$store.commit("setCustomerId", customerId);
              vm.$emit("loginSuccess");
              vm.saveLoading = false;
            } else {
              vm.$Swal
                .fire({
                  text: "Email / Password is incorrect.",
                  confirmButtonColor: "black",
                })
                .then((result) => {
                  if (result.value) {
                    vm.saveLoading = false;
                    vm.input = {
                      username: "",
                      password: "",
                    };
                  }
                });
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    onLoginAsGuest() {
      this.$store.commit("setStatusGuest", true);
      this.$emit("close");
    },
    onForgetPassword() {
      this.isForgetPassword = true;
    },
    async sendForgetPassword() {
      if (await this.$refs.observerForgetPassword.validate()) {
        var vm = this;
        let baseAPI = vm.baseAPI;
        vm.saveLoading = true;

        axios
          .post(`${baseAPI}auth/v1/forget-password`, {
            data: {
              email: vm.email,
            },
          })
          .then(async (response) => {
            if (response.data.message == "Success") {
              vm.email = "";
              vm.saveLoading = false;
              vm.statusSentEmail = true;
            }
          })
          .catch((error) => {
            vm.saveLoading = false;
            vm.email = "";
            vm.$Swal.fire({
              text: "Email not found",
              confirmButtonColor: "black",
            });
            // console.log(error.response);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
