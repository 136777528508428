<template>
  <div
    style="
      height: 100vh;
      padding-top: 50px;
      padding-bottom: 50px;
      overflow-y: auto;
    "
  >
    <Header></Header>
    <div v-if="loading" style="text-align: center; margin-top: 60px">
      <v-progress-circular
        indeterminate
        color="black"
        size="100"
        :width="7"
      ></v-progress-circular>
    </div>
    <div v-else>
      <template v-if="show">
        <v-layout class="topbar">
          <v-flex xs6 class="my-3">
            <v-btn @click="backToShop" icon ripple>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs6>
            <div
              style="margin-top: 16px; text-align: right; margin-right: 24px"
            >
              <v-btn
                color="grey lighten-2"
                depressed
                @click="openCartSummary"
                style="font-family: 'Dosis', sans-serif"
                class="py-3"
                icon
                ripple
              >
                <v-badge :color="cartItem > 0 ? '#000' : 'rgba(255,255,255,0)'">
                  <template v-slot:badge v-if="cartItem > 0">
                    <span class="white--text">{{ cartItem }}</span>
                  </template>
                  <v-icon large class="mr-2" color="black"
                    >mdi-cart-outline</v-icon
                  >
                  <!-- <span>สรุปตะกร้าสินค้า</span> -->
                </v-badge>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
        <div>
          <div class="product-photo">
            <slick ref="slick" :options="slickPhoto">
              <v-img
                v-for="(img, k) in show.gallery"
                :key="k"
                max-height="400"
                contain
                :src="img.image"
                cover
              ></v-img>
            </slick>
            <!-- :gradient="
                  isOutOfOrder
                    ? `to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)`
                    : ''
                " -->
            <!-- <div v-if="isOutOfOrder" class="outoforderatpicture">OUT OF ORDER</div> -->
          </div>
          <div class="product-detail">
            <v-container>
              <div class="product-name">{{ show.name }}</div>
              <div class="product-price">
                <div v-if="show.discountAmount > 0" class="discount">
                  ฿{{ show.standardPrice }}
                </div>
                <div>฿{{ show.totalAmount.toFixed(2) }}</div>
              </div>
              <div @click="showDetail = !showDetail" class="detail">
                <div
                  v-if="show.description"
                  v-html="remove_br_tags(show.description)"
                ></div>
              </div>
              <div v-if="show.sizes.length > 0">
                <div class="sizes-title">Size</div>

                <slick :options="slickSizes">
                  <div v-for="(size, k) in show.sizes" :key="k">
                    <div
                      v-if="size.isAvailable"
                      @click="selectsize(size)"
                      class="sizes"
                      :style="
                        sizeSelected == size
                          ? `background-color: black; color: white;`
                          : ''
                      "
                    >
                      <span
                        class="text-sizes"
                        :style="
                          sizeSelected == size
                            ? `color: white !important;font-weight: 300`
                            : ''
                        "
                        >{{ size.name }}</span
                      >
                    </div>
                  </div>
                </slick>
                <div class="text-right" v-if="show.imageSizeChartUrl">
                  <v-btn
                    text
                    ripple
                    @click="showShoesSizeModal = true"
                    style="text-decoration: underline"
                  >
                    <span>Size Guide</span>
                  </v-btn>
                </div>
              </div>
              <!-- <div
                v-if="sizeimg"
                class="text-right"
                @click="showimgsize = !showimgsize"
                style="
                  font-weight: 600;
                  text-decoration: underline;
                  font-family: 'Sarabun', sans-serif;
                  cursor: pointer;
                "
              >
                ดูข้อมูลไซส์
              </div>
              <div v-if="showimgsize">
                <v-img :src="sizeimg"></v-img>
              </div> -->
              <div>
                <div class="sizes-title text-xs-center">จำนวน</div>
                <div style="display: flex; justify-content: space-around">
                  <span style="padding-top: 8px">
                    <v-btn
                      icon
                      outlined
                      :disabled="quantity <= 0"
                      @click="onChangeQuantity('remove')"
                      :class="quantity <= 0 ? 'quantitybtnoff' : 'quantitybtn'"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </span>
                  <span
                    v-if="sizeSelectedData.available <= 0"
                    :style="`font-size: 35px;font-weight: 600; color: #d8d8d8`"
                    >{{ quantity }}</span
                  >
                  <span v-else :style="`font-size: 35px;font-weight: 600;`">{{
                    quantity
                  }}</span>
                  <span style="padding-top: 8px">
                    <v-btn
                      icon
                      outlined
                      :disabled="
                        !sizeSelectedData.available ||
                          sizeSelectedData.available <= quantity
                      "
                      @click="onChangeQuantity('add')"
                      :class="
                        !sizeSelectedData.available ||
                        sizeSelectedData.available <= quantity
                          ? 'quantitybtnoff'
                          : 'quantitybtn'
                      "
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </span>
                </div>
              </div>
            </v-container>
          </div>
        </div>
        <div class="btn-checkout-footer">
          <v-layout wrap>
            <v-flex xs5>
              <v-btn
                @click="onAddCart"
                :loading="addloading"
                :disabled="addloading || quantity == 0"
                depressed
                color="#FFF"
                style="
                  font-weight: 600;
                  width: 100%;
                  height: 50px;
                  border-radius: 0 !important;
                  border: 1px solid #cd999d;
                "
                :style="
                  addloading || quantity > 0 ? 'color: black;' : 'color: white;'
                "
              >
                <!-- :style="`width: ${!animatedcart ? '80%' : '15%'}`"
              :class="{
                  'expand animated-addcart': !animatedcart,
                  'contract animated-addcart': animatedcart,
                }" -->

                <v-icon>mdi-cart-plus</v-icon>
                <span class="addcart">{{
                  sizeSelectedData.available <= 0
                    ? "OUT OF ORDER"
                    : "Add to cart"
                }}</span>
              </v-btn>
            </v-flex>
            <v-flex xs7>
              <v-btn
                :disabled="quantity == 0"
                class="text-center"
                @click="onBuyandCheckout"
                color="#FFF"
                style="
                  font-weight: 600;
                  width: 100%;
                  height: 50px;
                  border-radius: 0 !important;
                  border: 1px solid #cd999d;
                  color: #fff;
                  background: #cd999d;
                "
              >
                <div class="d-flex justify-center">
                  <v-icon>mdi-cart-outline</v-icon>
                  <span class="pl-1 pt-1">Checkout</span>
                </div>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </template>
      <template v-else>
        <div class="text-center" style="padding-top: 80px">
          <div style="font-family: 'Dosis', san-serif; font-size: 48px">
            Product details not found
          </div>
          <div style="margin-top: 25px; text-align: center">
            <v-btn
              @click="backToShop"
              color="black"
              style="color: white"
              rounded
              >BACK TO SHOP</v-btn
            >
          </div>
        </div>
      </template>
    </div>

    <!-- Modal Login -->
    <v-dialog width="350px" v-model="loginModal" persistent>
      <LoginForm
        @close="loginModal = false"
        @onRegister="onRegister"
        @loginSuccess="loginSuccess"
      ></LoginForm>
    </v-dialog>

    <!-- Modal registerMember -->
    <v-dialog width="350px" v-model="registerMemberModal" persistent>
      <RegisterForm
        @close="registerMemberModal = false"
        @onBack="onBackToLogin"
        @registerSuccess="registerSuccess"
      >
      </RegisterForm>
    </v-dialog>

    <!-- Modal show shoes size -->
    <v-dialog width="350px" v-model="showShoesSizeModal" persistent>
      <v-card class="pa-4">
        <img :src="show.imageSizeChartUrl" width="100%" height="auto" />
        <div class="text-center">
          <v-btn @click="showShoesSizeModal = false" text ripple>
            <span>CLOSE</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import queryString from "query-string";
import sizeimg from "@/assets/js/sizeimg.js";
import Header from "@/components/Header.vue";
import LoginForm from "./LoginForm.vue";
import RegisterForm from "./RegisterForm.vue";

export default {
  name: "Product",
  components: {
    Header,
    LoginForm,
    RegisterForm,
  },
  data() {
    return {
      showimgsize: false,
      // serverUrl: "https://zwizaitest.herokuapp.com/",
      serverUrl: process.env.VUE_APP_API_URL,
      show: {},
      promoprice: "",
      loading: true,
      addloading: false,
      quantity: 0,
      sizeSelected: "",
      sizeSelectedData: "",
      showDetail: false,
      query: "",
      animatedcart: false,
      detail: "",
      totalItem: 0,
      // "กางเกง Monotone era-won เป็นเทคโนโลยีพิเศษ ที่ผ้าสามารถยืดได้ 2 ด้าน (Two Way Stretch) มีความสบายเวลาสวมใส่ ผ้าทุกชิ้นนำเข้าจากโรงงานชั้นนำที่ประเทศญี่ปุ่น และผ่านการ QC อย่างละเอียด รวมไปถึงการทอด้ายที่ทำให้ผ้ามีความเนียนนุ่ม",

      slickSizes: {
        slidesToShow: 2,
        variableWidth: true,
        infinite: false,
        arrows: false,
      },
      slickPhoto: {
        lazyLoad: "ondemand",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      },
      statusAddandCheckout: false,
      registerMemberModal: false,
      loginModal: false,
      showShoesSizeModal: false,
    };
  },
  computed: {
    cartItem() {
      return this.$store.state.cartItems.length;
    },
    imageShoesSize() {
      var name = this.show.name.toLowerCase();
      var nameArray = name.split(" ");

      var shoesList = ["audrey", "hepburn", "heel", "sandals"];
      var isShoes = shoesList.filter((item) => nameArray.includes(item));
      if (isShoes.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    baseAPI() {
      return this.$store.state.oandbBaseAPI;
    },
  },
  async mounted() {
    var vm = this;
    this.queryinit();
    // await vm.getCartItemsByCustomer();
    await vm.getProductDetail();
  },
  methods: {
    calpromotionerawonbyproductstockid(id) {
      var data = { productstockid: id, price: this.price.price };
      axios
        .post(this.serverUrl + "calpromotionerawonbyproductstockid", data, {
          headers: {
            Authorization: "Basic userid sessiontoken",
          },
        })
        .then((response) => {
          this.promoprice = response.data.totalpricepromo;
        });
    },
    selectsize(size) {
      this.sizeSelected = size;
      this.getStockBySku(size.sku);
    },
    queryinit() {
      var vm = this;
      vm.query = vm.$route.query["liff.state"]
        ? queryString.parse(vm.$route.query["liff.state"])
        : vm.$route.query;

      if (vm.query.pageid) vm.pageid = vm.query.pageid;
    },
    getProductDetail() {
      var vm = this;
      var product = this.query.product;
      let baseAPI = vm.baseAPI;
      console.log(this.query);
      this.loading = true;
      axios
        .get(
          `${baseAPI}inventory/v1/product/models?query=${product}&warehouseId=13&storeWebsiteId=1&langId=1`
        )
        .then((response) => {
          if (response.status == 200) {
            vm.loading = false;

            if (response.data.data) {
              vm.show = response.data.data[0];

              if (vm.show.sizes.length > 0) {
                vm.sizeSelected = vm.show.sizes[0].code;
              }
            }
          }
        })
        .catch((error) => {
          if (error.response.data.statusCode == 400) {
            // console.log(error.response.data.message);
            vm.$Swal
              .fire({
                text: "Product Not Found",
                confirmButtonColor: "black",
              })
              .then((result) => {
                if (result.value) {
                  vm.backToShop();
                }
              });
          }
        });
    },
    getStockBySku(sku) {
      var vm = this;
      vm.sizeSelectedData = "";
      let baseAPI = vm.baseAPI;
      axios
        .get(`${baseAPI}inventory/v1/stock/warehouses/search/sku?query=${sku}`)
        .then((response) => {
          if (response.status == 200) {
            vm.sizeSelectedData = response.data.data[0];

            if (this.sizeSelectedData.available <= 0) {
              vm.quantity = 0;
            } else {
              vm.quantity = 1;
            }
          }
        });
    },
    onChangeQuantity(action) {
      var vm = this;
      if (action == "add") {
        vm.quantity = vm.quantity + 1;
      } else {
        vm.quantity = vm.quantity - 1;
      }
    },
    remove_br_tags(html) {
      var html = html.replace(/[<]br[^>]*[>]/gi, "");
      return html;
    },
    onCheckCustomerLevel() {
      var vm = this;
      var customerId = vm.$store.state.customerId;
      let baseAPI = vm.baseAPI;

      axios
        .get(`${baseAPI}customer/v1/customers/code?query=${customerId}`)
        .then((response) => {
          if (response.data.message == "Success") {
            var level = response.data.data[0].level;
            var statusGuest = vm.$store.state.statusGuest;
            // console.log('level: ',level);
            // console.log('statusGuest: ',statusGuest);
            if (level == "guest" && !statusGuest) {
              vm.loginModal = true;
            } else {
              vm.checkCustomerCart();
            }
          } else {
            vm.$Swal.fire({
              text: response.data.reason,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onAddCart() {
      this.onCheckCustomerLevel();
    },
    checkCustomerCart() {
      var vm = this;
      var customerId = vm.$store.state.customerId;
      let baseAPI = vm.baseAPI;

      axios
        .get(`${baseAPI}transaction/v1/carts/customer?query=${customerId}`)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.length > 0) {
              vm.onAddItemToCart();
            } else {
              vm.createCustomerCart();
            }
          } else {
            vm.$Swal.fire({
              text: response.data.reason,
            });
          }
        })
        .catch((error) => {
          if (error.response.data.statusCode == 400) {
            vm.createCustomerCart();
          }
        });
    },
    createCustomerCart() {
      var vm = this;
      var customerId = vm.$store.state.customerId;
      let baseAPI = vm.baseAPI;

      axios
        .post(`${baseAPI}transaction/v1/carts/ecom?query=WEB|OB|THAI`, {
          data: {
            customerCode: customerId,
          },
        })
        .then((response) => {
          if (response.data.message == "Success") {
            vm.onAddItemToCart();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    onAddItemToCart() {
      var vm = this;
      var customerId = vm.$store.state.customerId;
      var sku = vm.sizeSelected.sku;
      let baseAPI = vm.baseAPI;

      axios
        .post(`${baseAPI}transaction/v1/cart/items/sku?query=${customerId}`, {
          data: {
            sku: sku,
          },
        })
        .then((response) => {
          if (response.data.message == "Success") {
            var responseCartItem = response.data.data[0].transactionItems;
            vm.$store.commit("setCartItems", responseCartItem);

            if (vm.quantity > 1) {
              vm.updateItemQty();
            }

            if (vm.statusAddandCheckout) {
              vm.openCartSummary();
            }
          }
        });
    },
    updateItemQty() {
      var vm = this;
      var customerId = vm.$store.state.customerId;
      var sku = vm.sizeSelected.sku;
      let baseAPI = vm.baseAPI;

      axios
        .patch(`${baseAPI}transaction/v1/cart/items?query=${customerId}`, {
          data: {
            sku: sku,
            quantity: vm.quantity,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            vm.sizeSelectedData = "";
            vm.sizeSelected = "";
            vm.quantity = 0;
          }
        });
    },
    openCartSummary() {
      var vm = this;
      const query = vm.$route.query;
      delete query.product;

      vm.$router
        .push({
          path: "cart-summary",
          query: { ...vm.$route.query },
        })
        .catch({});
    },
    async onBuyandCheckout() {
      this.statusAddandCheckout = true;
      await this.onAddCart();
    },
    // getCartItemsByCustomer() {
    //   var vm = this;
    //   var customerId = vm.$store.state.customerId;
    //   axios
    //     .get("https://api.oandb.style/api/transaction/v1/carts/customer?query=" +customerId)
    //     .then(response => {
    //       if(response.message == 'Success' && response.data.length > 0) {
    //          var responseCartItem = response.data.data[0].transactionItems;
    //         //  console.log(responseCartItem);
    //         //  var cartItems = vm.$store.state.cartItems;
    //           vm.$store.commit('setCartItems',responseCartItem);

    //         } else {
    //           vm.$Swal.fire({
    //             text: response.data.reason,
    //          });
    //         }
    //     })
    //     .catch(error => {
    //         console.log(error.response);
    //     });
    // },
    backToShop() {
      var vm = this;
      const query = vm.$route.query;
      delete query.product;

      vm.$router
        .push({
          path: "all-products",
          query: { ...vm.$route.query },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    async getCartItemsByCustomer(customerId) {
      var vm = this;
      let baseAPI = vm.baseAPI;
      axios
        .get(`${baseAPI}transaction/v1/carts/customer?query=${customerId}`)
        .then((response) => {
          if (response.data.message == "Success") {
            if (response.data.data.length > 0) {
              var responseCartItem = response.data.data[0].transactionItems;
              vm.$store.commit("setCartItems", responseCartItem);
            } else {
              vm.createCustomerCart();
            }
          }
        });
    },

    async loginSuccess() {
      var vm = this;
      var customerId = vm.$store.state.customerId;

      // await vm.getCartItemsByCustomer(customerId);
      await vm.checkCustomerCart();
      vm.loginModal = false;
      vm.$router
        .push({
          path: "product",
          query: { ...vm.$route.query, customerid: customerId },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    registerSuccess() {
      var vm = this;
      var customerId = vm.$store.state.customerId;

      vm.registerMemberModal = false;

      vm.$Swal
        .fire({
          text: "Register successfully.",
          confirmButtonColor: "#000",
        })
        .then((result) => {
          if (result.value) {
            vm.createCustomerCart();
            vm.$router
              .push({
                path: "product",
                query: { ...vm.$route.query, customerid: customerId },
              })
              .catch((error) => {
                if (error.name != "NavigationDuplicated") {
                  throw error;
                }
              });
          }
        });
    },
    onRegister() {
      var vm = this;
      vm.loginModal = false;
      vm.registerMemberModal = true;
    },
    onBackToLogin() {
      var vm = this;
      vm.registerMemberModal = false;
      vm.loginModal = true;
    },
  },
  filter: {
    numFormat: function(value) {
      return new Intl.NumberFormat("th-TH", {
        style: "currency",
        currency: "THB",
      }).format(value);
    },
  },
};
// productid
// productstockid
</script>
<style>
body,
html {
  font-family: "Dosis", "Sarabun", sans-serif;
}
.topbar {
  background: #fff;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 1;
}
.outoforderatpicture {
  width: 100%;
  text-align: center;
  opacity: 0.5;
  font-weight: 400;
  font-size: 3em;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);

  transform-origin: center;
}
.product-name {
  font-family: "Dosis", sans-serif;
  font-weight: bold;
  font-size: 23px;
  width: 100%;
  word-break: break-word;
}
.product-price {
  margin-top: 20px;
  font-family: "Dosis", sans-serif;
  text-align: right;
  font-weight: bold;
  position: relative;
  font-size: 25px;
}
.discount {
  font-size: 17px !important;
  text-decoration: line-through;
  color: #717171 !important;
  font-weight: 400;
}
.product-detail {
  padding-bottom: 25px;
}
.product-photo {
  width: 100%;
  padding: 0.6em;
  position: relative;
}
.sizes-title {
  margin-top: 10px;
  font-family: "Dosis", sans-serif;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  color: rgb(145, 145, 145);
}

.sizes-title:before,
.sizes-title:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #cfcfcf;
  margin: 15px;
}
.sizes {
  border: 1px solid #aaaaaa;
  font-family: "Dosis", sans-serif;
  font-size: 19px;
  font-weight: 600;
  width: 55px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 100px;
  height: 55px;
  position: relative;
  transition: 0.15s ease-in-out;
}
.text-sizes {
  position: absolute;
  color: #9e9e9e;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.15s ease-in-out;
}
.detail {
  color: rgb(128, 128, 128);
  margin-top: 12px;
  /* padding-left: 18px;
  padding-right: 18px; */
  font-weight: 400;
  font-size: 16px;
  font-family: "Sarabun", sans-serif;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.addcart {
  margin-left: 10px;
  /* font-size: 16px; */
  font-family: "Dosis", sans-serif;
}
.quantitybtn {
  border-bottom-width: 4px !important;
  border-color: black !important;
  color: black !important;
}
.quantitybtnoff {
  color: black !important;
  border-color: grey !important;
  border-bottom-width: 1px !important;
}
.slick-slide {
  outline: none;
}

@keyframes expand {
  from {
    width: 15%;
  }

  to {
    width: 80%;
  }
}

.expand {
  -webkit-animation-name: expand;
  animation-name: expand;
}

@keyframes contract {
  from {
    width: 80%;
  }
  to {
    width: 15%;
  }
}

.contract {
  -webkit-animation-name: contract;
  animation-name: contract;
}
.animated-addcart {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  /*   -webkit-animation-fill-mode: both;
  animation-fill-mode: both; */
}
.btn-checkout-footer {
  /* background: #cd999d; */
  cursor: pointer;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Dosis", san-serif;
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}
.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background: #f5f5f5 !important;
  border: 1px solid #aaaaaa !important;
}
</style>
