var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"vth-addr-container"},[_c('div',{staticClass:"vth-addr-input-container"},[_c('v-text-field',{ref:"input",staticClass:"vth-addr-input",class:{
        'vth-addr-input-size-small': _vm.size === 'small',
        'vth-addr-input-size-default': _vm.size === 'default',
        'vth-addr-input-size-medium': _vm.size === 'medium',
        'vth-addr-input-size-large': _vm.size === 'large',
      },attrs:{"type":_vm.texttype,"placeholder":_vm.placeholder,"outlined":"","dense":"","color":_vm.color,"hide-details":_vm.hideDetails,"error-messages":_vm.errorMessages,"success":_vm.success,"autocomplete":"disabled"},on:{"focus":function($event){_vm.hasFocus = true},"blur":function($event){_vm.hasFocus = false},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.pressArrow('up')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.pressArrow('down')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.pressEnter()}]},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),(_vm.resultsFromSearch.length && _vm.isOpenListContainer)?_c('div',{ref:"dropdown",staticClass:"vth-addr-list-container",style:({ top: _vm.findListContainerPosition() })},_vm._l((_vm.resultsFromSearch),function(item,index){return _c('div',{key:index,staticClass:"vth-addr-list",class:{ 'vth-addr-list-on-focused': _vm.itemOnFocus === index },style:({
          'background-color': _vm.itemOnFocus === index ? _vm.currentColor : '#fff',
        }),on:{"mouseover":function($event){_vm.itemOnFocus = index},"mouseout":function($event){_vm.itemOnFocus = -1},"click":function($event){return _vm.clickSelectItem(item)}}},[_c('div',{staticClass:"vth-addr-box-item-top"},[_c('span',{staticClass:"item-first",class:{
              'vth-addr-box-item-top-focused':
                _vm.itemOnFocus === index && _vm.currentColor !== '#f5f5f5',
            }},[_vm._v(_vm._s(_vm.itemFirst(item)))]),_c('div',{staticClass:"vth-addr-float-right"},[_c('span',{staticClass:"vth-addr-item-second",class:{
                'vth-addr-box-item-top-focused':
                  _vm.itemOnFocus === index && _vm.currentColor !== '#f5f5f5',
              }},[_vm._v(_vm._s(_vm.itemSecond(item)))]),_c('span',{staticClass:"vth-addr-item-third",class:{
                'vth-addr-box-item-top-focused':
                  _vm.itemOnFocus === index && _vm.currentColor !== '#f5f5f5',
              }},[_vm._v(_vm._s(_vm.itemThird(item)))])])]),_c('div',{staticClass:"vth-addr-box-item-bottom"},[_c('span',{staticClass:"vth-addr-item-first vth-addr-font-weight-bold",style:({
              color:
                _vm.itemOnFocus === index && _vm.currentColor !== '#f5f5f5'
                  ? '#fff'
                  : '#000',
            })},[_vm._v(_vm._s(_vm.itemFourth(item)))])])])}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }