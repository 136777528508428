<template>
  <div>
    <template>
      <v-card class="pa-4">
        <!-- <div class="text-right">
          <v-btn @click="$emit('close')" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div> -->

        <div class="mt-2 mb-4 text-center">
          <h2>ลงทะเบียน</h2>
        </div>
        <ValidationObserver ref="observer">
          <div>
            <label class="colabel">
              ชื่อ
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="name"
              :rules="{
                required: true,
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="input.name"
                color="black"
                outlined
                :error-messages="errors"
                dense
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              เบอร์โทรศัพท์
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="phone"
              :rules="{
                required: true,
                min: 9,
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="input.phone"
                type="number"
                color="black"
                maxlength="10"
                :error-messages="errors"
                outlined
                dense
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              อีเมล
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="email"
              :rules="'required|email'"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="input.email"
                type="email"
                color="black"
                :error-messages="errors"
                outlined
                dense
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              รหัสผ่าน
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="password"
              :rules="'required'"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="input.password"
                type="password"
                color="black"
                :error-messages="errors"
                outlined
                dense
              ></v-text-field>
            </ValidationProvider>
          </div>
        </ValidationObserver>

        <div class="text-center">
          <v-btn
            style="
              font-weight: 600;
              font-size: 20px;
              color: white;
              height: 48px;
            "
            :loading="saveLoading"
            dark
            block
            @click="saveRegister"
            >ยืนยัน</v-btn
          >
          <v-btn
            class="mt-2"
            style="font-size: 14px"
            text
            ripple
            @click="$emit('onBack')"
            >ย้อนกลับ</v-btn
          >
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import queryString from "query-string";
const saltedMd5 = require("salted-md5");

export default {
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      registerMember: false,
      input: {
        name: "",
        phone: "",
        email: "",
        password: "",
      },
      saveLoading: false,
    };
  },
  methods: {
    async saveRegister() {
      if (await this.$refs.observer.validate()) {
        var vm = this;

        var data = {
          email: vm.input.email,
          fullName: vm.input.name,
          phoneNo: vm.input.phone,
          password: saltedMd5(vm.input.password, "BYhZ6Y942Myn"),
          userid: vm.$route.query.userid,
          pageid: vm.$route.query.pageid,
        };

        vm.saveLoading = true;
        axios
          .post(vm.serverUrl + "v2/oab/customer/create", data, {
            headers: {
              AUTHORIZATION: "Bearer xxx xxx",
            },
          })
          .then(async (response) => {
            if (response.data.responsecode == 200) {
              var customerId = response.data.data.data.code;
              await vm.$store.commit("setCustomerId", customerId);
              vm.$emit("registerSuccess");
              vm.saveLoading = false;
              vm.registerMember = false;
            } else {
              vm.input = {
                name: "",
                phone: "",
                email: "",
                password: "",
              };

              vm.$Swal.fire({
                text: response.data.reason,
                confirmButtonColor: "#000",
              });
              vm.$emit("close");
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
