<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import axios from "axios";
import queryString from "query-string";
export default {
  name: "App",
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      loading: true,
      query: "",
      customerId: null,
      isSkuTransportation: null,
    };
  },
  async mounted() {
    var vm = this;
    await vm.queryinit();
    await vm.init();
  },
  computed: {
    baseAPI() {
      return this.$store.state.oandbBaseAPI;
    },
  },
  methods: {
    async init() {
      var vm = this;
      // var liffid = "1656632077-kgP2yp62"; // localhost
      var liffid = "1656632077-mPNGB2AG"; // oandb.zwiz.ai
      // var liffid = "1656632077-v4pKk6aK"; // oandbtest.zwiz.ai

      // if (this.query.pageid == "162369877131894")
      //   liffid = "1654294779-oOEnYax9";

      if (this.query.apptype == "line") {
        await vm.initializeLiff(liffid);
        // this.$store.state.userid = await this.$store.state.profile.userId + "__" + this.query.pageid;
        this.$store.state.userid = this.query.userid
          ? this.query.userid
          : this.$store.state.profile.userId + "__" + this.query.pageid;
        this.$store.state.pageid = this.query.pageid;

        vm.getCategories();
        vm.checkMember();
      } else {
        try {
          window.extAsyncInit = function() {
            // the Messenger Extensions JS SDK is done loading
            vm.loading = false;
          };
          // this.$store.state.userid = this.query.userid + "__" + this.query.pageid;
          this.$store.state.userid = this.query.userid;
          this.$store.state.pageid = this.query.pageid;

          vm.getCategories();
          vm.checkMember();
        } catch (error) {
          // console.log(error);
          console.log(error.message);
        }
      }
    },
    async initializeLiff(myLiffId) {
      var vm = this;
      var redirectUri = window.location.href;
      try {
        await this.$liff.init({
          liffId: myLiffId,
        });

        if (await this.$liff.isLoggedIn()) {
          this.$store.state.profile = await this.$liff.getProfile();
          vm.loading = false;
        } else {
          await this.$liff.login({ redirectUri });
        }
      } catch (error) {
        // alert(error.message);
      }
    },
    queryinit() {
      var vm = this;
      vm.query = vm.$route.query["liff.state"]
        ? queryString.parse(vm.$route.query["liff.state"])
        : vm.$route.query;

      if (vm.query.pageid) vm.pageid = vm.query.pageid;
    },
    async getCategories() {
      var vm = this;
      let baseAPI = this.baseAPI;
      await axios
        .get(
          `${baseAPI}inventory/v1/product/models/groups/filter?query=all-products&langId=1&storeWebsiteId=1`
        )
        .then((response) => {
          if (response.status == 200) {
            vm.$store.state.allCategoryList =
              response.data.data.filter.displayCates;
          }
        });
    },
    async checkMember() {
      var vm = this;
      var userid = vm.query.userid;
      var pageid = vm.query.pageid;

      var url = "v2/oab/customer/checkcustomer/" + userid + "?pageid=" + pageid;
      axios
        .get(vm.serverUrl + url, {
          headers: { AUTHORIZATION: "Bearer xxx xxx" },
        })
        .then(async (response) => {
          if (response.data.responsecode == 200) {
            if (
              response.data.data.length == 0 ||
              (response.data.data && response.data.data.code == "")
            ) {
              // if(response.data.data && response.data.data.code == '') {
              vm.createGuestCustomer();
            } else {
              var customerId = response.data.data.code;
              vm.$store.commit("setCustomerId", customerId);
              await vm.getCartItemsByCustomer(customerId);
            }
          }
        });
    },

    async getCartItemsByCustomer(customerId) {
      var vm = this;
      let baseAPI = this.baseAPI;
      axios
        .get(`${baseAPI}transaction/v1/carts/customer?query=` + customerId)
        .then((response) => {
          if (
            response.data.message == "Success" &&
            response.data.data.length > 0
          ) {
            var responseCartItem = response.data.data[0].transactionItems;

            responseCartItem.find((item) => {
              if (item.product.name == "Transportation Fee") {
                // console.log(item.product.code)
                this.isSkuTransportation = item.product.code;
                // return item.product.code
              }
            });

            if (this.isSkuTransportation) {
              this.removeTransportationFee(customerId);
            } else {
              vm.$store.commit("setCartItems", responseCartItem);
            }
          } else {
            vm.$store.commit("setCartItems", {});
          }
        });

      var view = vm.$route.query.view;
      const query = vm.$route.query;
      delete query.view;

      if (vm.$route.query.cate) {
        vm.$store.state.previousCategory = {
          categorySlug: vm.$route.query.cate,
          pageNumber: 1,
        };
        delete query.cate;
      }

      vm.$router
        .push({
          path: view,
          query: { ...vm.$route.query, customerid: customerId },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    async removeTransportationFee(customerId) {
      var vm = this;
      var sku = this.isSkuTransportation;
      let baseAPI = this.baseAPI;
      axios
        .delete(
          `${baseAPI}transaction/v1/cart/items?query=${customerId}&sku=${sku}`
        )
        .then((response) => {
          if (response.status == 200) {
            vm.getCartItemsByCustomer();
            this.isSkuTransportation = null;
          }
        });
    },
    async createGuestCustomer() {
      var vm = this;
      let baseAPI = this.baseAPI;
      axios.post(`${baseAPI}customer/v1/customers/guest`).then((response) => {
        if (response.data.message == "Success") {
          var customerId = response.data.data[0].customer.code;
          vm.$store.commit("setCustomerId", customerId);

          const query = vm.$route.query;
          var view = query.view;
          delete query.view;

          if (vm.$route.query.cate) {
            vm.$store.state.previousCategory = {
              categorySlug: vm.$route.query.cate,
              pageNumber: 1,
            };
            delete query.cate;
          }

          vm.$router
            .push({
              path: view,
              query: { ...vm.$route.query, customerid: customerId },
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        } else {
          vm.$Swal.fire({
            text: response.data.reason,
          });
          vm.$liff.closeWindow();
        }
      });
    },
  },
};
</script>
<style>
.Kanit {
  font-family: "Kanit", sans-serif;
}
</style>
