import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serverUrl: process.env.VUE_APP_API_URL,
    // oandbBaseAPI: 'https://api-dev.oandb.style/api/', //Dev
    oandbBaseAPI: "https://api.oandb.style/api/", //Production
    statusGuest: false,
    customerId: "",
    cartItems: [],
    redirect: "",
    userid: "",
    pageid: "",
    profile: [],
    currentCategory: "",
    allCategoryList: [],
    previousCategory: {
      categorySlug: "",
      pageNumber: "",
    },
    country: [
      {
        id: 1,
        code: "TH",
        phoneCode: "+66",
        nameTh: "ประเทศไทย",
        nameEn: "Thailand",
      },
    ],
    regions: [
      {
        id: 1,
        countryId: 1,
        nameTh: "ภาคเหนือ",
        nameEn: "Northern",
      },
      {
        id: 2,
        countryId: 1,
        nameTh: "ภาคกลาง",
        nameEn: "Central",
      },
      {
        id: 3,
        countryId: 1,
        nameTh: "ภาคตะวันออกเฉียงเหนือ",
        nameEn: "Northeastern",
      },
      {
        id: 4,
        countryId: 1,
        nameTh: "ภาคตะวันตก",
        nameEn: "Western",
      },
      {
        id: 5,
        countryId: 1,
        nameTh: "ภาคตะวันออก",
        nameEn: "Eastern",
      },
      {
        id: 6,
        countryId: 1,
        nameTh: "ภาคใต้",
        nameEn: "Southern",
      },
    ],
    province: [
      {
        id: 65,
        regionId: 6,
        code: "81",
        nameTh: "กระบี่",
        nameEn: "Krabi",
      },
      {
        id: 1,
        regionId: 2,
        code: "10",
        nameTh: "กรุงเทพมหานคร",
        nameEn: "Bangkok",
      },
      {
        id: 57,
        regionId: 4,
        code: "71",
        nameTh: "กาญจนบุรี",
        nameEn: "Kanchanaburi",
      },
      {
        id: 35,
        regionId: 3,
        code: "46",
        nameTh: "กาฬสินธุ์",
        nameEn: "Kalasin",
      },
      {
        id: 50,
        regionId: 2,
        code: "62",
        nameTh: "กำแพงเพชร",
        nameEn: "Kamphaeng Phet",
      },
      {
        id: 29,
        regionId: 3,
        code: "40",
        nameTh: "ขอนแก่น",
        nameEn: "Khon Kaen",
      },
      {
        id: 13,
        regionId: 5,
        code: "22",
        nameTh: "จันทบุรี",
        nameEn: "Chanthaburi",
      },
      {
        id: 15,
        regionId: 5,
        code: "24",
        nameTh: "ฉะเชิงเทรา",
        nameEn: "Chachoengsao",
      },
      {
        id: 11,
        regionId: 5,
        code: "20",
        nameTh: "ชลบุรี",
        nameEn: "Chon Buri",
      },
      {
        id: 9,
        regionId: 2,
        code: "18",
        nameTh: "ชัยนาท",
        nameEn: "Chai Nat",
      },
      {
        id: 25,
        regionId: 3,
        code: "36",
        nameTh: "ชัยภูมิ",
        nameEn: "Chaiyaphum",
      },
      {
        id: 70,
        regionId: 6,
        code: "86",
        nameTh: "ชุมพร",
        nameEn: "Chumphon",
      },
      {
        id: 46,
        regionId: 1,
        code: "57",
        nameTh: "เชียงราย",
        nameEn: "Chiang Rai",
      },
      {
        id: 39,
        regionId: 1,
        code: "50",
        nameTh: "เชียงใหม่",
        nameEn: "Chiang Mai",
      },
      {
        id: 73,
        regionId: 6,
        code: "92",
        nameTh: "ตรัง",
        nameEn: "Trang",
      },
      {
        id: 14,
        regionId: 5,
        code: "23",
        nameTh: "ตราด",
        nameEn: "Trat",
      },
      {
        id: 51,
        regionId: 4,
        code: "63",
        nameTh: "ตาก",
        nameEn: "Tak",
      },
      {
        id: 17,
        regionId: 2,
        code: "26",
        nameTh: "นครนายก",
        nameEn: "Nakhon Nayok",
      },
      {
        id: 59,
        regionId: 2,
        code: "73",
        nameTh: "นครปฐม",
        nameEn: "Nakhon Pathom",
      },
      {
        id: 37,
        regionId: 3,
        code: "48",
        nameTh: "นครพนม",
        nameEn: "Nakhon Phanom",
      },
      {
        id: 19,
        regionId: 3,
        code: "30",
        nameTh: "นครราชสีมา",
        nameEn: "Nakhon Ratchasima",
      },
      {
        id: 64,
        regionId: 6,
        code: "80",
        nameTh: "นครศรีธรรมราช",
        nameEn: "Nakhon Si Thammarat",
      },
      {
        id: 48,
        regionId: 2,
        code: "60",
        nameTh: "นครสวรรค์",
        nameEn: "Nakhon Sawan",
      },
      {
        id: 3,
        regionId: 2,
        code: "12",
        nameTh: "นนทบุรี",
        nameEn: "Nonthaburi",
      },
      {
        id: 77,
        regionId: 6,
        code: "96",
        nameTh: "นราธิวาส",
        nameEn: "Narathiwat",
      },
      {
        id: 44,
        regionId: 1,
        code: "55",
        nameTh: "น่าน",
        nameEn: "Nan",
      },
      {
        id: 27,
        regionId: 3,
        code: "38",
        nameTh: "บึงกาฬ",
        nameEn: "Bueng Kan",
      },
      {
        id: 20,
        regionId: 3,
        code: "31",
        nameTh: "บุรีรัมย์",
        nameEn: "Buri Ram",
      },
      {
        id: 4,
        regionId: 2,
        code: "13",
        nameTh: "ปทุมธานี",
        nameEn: "Pathum Thani",
      },
      {
        id: 63,
        regionId: 4,
        code: "77",
        nameTh: "ประจวบคีรีขันธ์",
        nameEn: "Prachuap Khiri Khan",
      },
      {
        id: 16,
        regionId: 5,
        code: "25",
        nameTh: "ปราจีนบุรี",
        nameEn: "Prachin Buri",
      },
      {
        id: 75,
        regionId: 6,
        code: "94",
        nameTh: "ปัตตานี",
        nameEn: "Pattani",
      },
      {
        id: 5,
        regionId: 2,
        code: "14",
        nameTh: "พระนครศรีอยุธยา",
        nameEn: "Phra Nakhon Si Ayutthaya",
      },
      {
        id: 45,
        regionId: 1,
        code: "56",
        nameTh: "พะเยา",
        nameEn: "Phayao",
      },
      {
        id: 66,
        regionId: 6,
        code: "82",
        nameTh: "พังงา",
        nameEn: "Phang-nga",
      },
      {
        id: 74,
        regionId: 6,
        code: "93",
        nameTh: "พัทลุง",
        nameEn: "Phatthalung",
      },
      {
        id: 54,
        regionId: 2,
        code: "66",
        nameTh: "พิจิตร",
        nameEn: "Phichit",
      },
      {
        id: 53,
        regionId: 2,
        code: "65",
        nameTh: "พิษณุโลก",
        nameEn: "Phitsanulok",
      },
      {
        id: 62,
        regionId: 4,
        code: "76",
        nameTh: "เพชรบุรี",
        nameEn: "Phetchaburi",
      },
      {
        id: 55,
        regionId: 2,
        code: "67",
        nameTh: "เพชรบูรณ์",
        nameEn: "Phetchabun",
      },
      {
        id: 43,
        regionId: 1,
        code: "54",
        nameTh: "แพร่",
        nameEn: "Phrae",
      },
      {
        id: 67,
        regionId: 6,
        code: "83",
        nameTh: "ภูเก็ต",
        nameEn: "Phuket",
      },
      {
        id: 33,
        regionId: 3,
        code: "44",
        nameTh: "มหาสารคาม",
        nameEn: "Maha Sarakham",
      },
      {
        id: 38,
        regionId: 3,
        code: "49",
        nameTh: "มุกดาหาร",
        nameEn: "Mukdahan",
      },
      {
        id: 47,
        regionId: 1,
        code: "58",
        nameTh: "แม่ฮ่องสอน",
        nameEn: "Mae Hong Son",
      },
      {
        id: 24,
        regionId: 3,
        code: "35",
        nameTh: "ยโสธร",
        nameEn: "Yasothon",
      },
      {
        id: 76,
        regionId: 6,
        code: "95",
        nameTh: "ยะลา",
        nameEn: "Yala",
      },
      {
        id: 34,
        regionId: 3,
        code: "45",
        nameTh: "ร้อยเอ็ด",
        nameEn: "Roi Et",
      },
      {
        id: 69,
        regionId: 6,
        code: "85",
        nameTh: "ระนอง",
        nameEn: "Ranong",
      },
      {
        id: 12,
        regionId: 5,
        code: "21",
        nameTh: "ระยอง",
        nameEn: "Rayong",
      },
      {
        id: 56,
        regionId: 4,
        code: "70",
        nameTh: "ราชบุรี",
        nameEn: "Ratchaburi",
      },
      {
        id: 7,
        regionId: 2,
        code: "16",
        nameTh: "ลพบุรี",
        nameEn: "Lop Buri",
      },
      {
        id: 41,
        regionId: 1,
        code: "52",
        nameTh: "ลำปาง",
        nameEn: "Lampang",
      },
      {
        id: 40,
        regionId: 1,
        code: "51",
        nameTh: "ลำพูน",
        nameEn: "Lamphun",
      },
      {
        id: 31,
        regionId: 3,
        code: "42",
        nameTh: "เลย",
        nameEn: "Loei",
      },
      {
        id: 22,
        regionId: 3,
        code: "33",
        nameTh: "ศรีสะเกษ",
        nameEn: "Si Sa Ket",
      },
      {
        id: 36,
        regionId: 3,
        code: "47",
        nameTh: "สกลนคร",
        nameEn: "Sakon Nakhon",
      },
      {
        id: 71,
        regionId: 6,
        code: "90",
        nameTh: "สงขลา",
        nameEn: "Songkhla",
      },
      {
        id: 72,
        regionId: 6,
        code: "91",
        nameTh: "สตูล",
        nameEn: "Satun",
      },
      {
        id: 2,
        regionId: 2,
        code: "11",
        nameTh: "สมุทรปราการ",
        nameEn: "Samut Prakarn",
      },
      {
        id: 61,
        regionId: 2,
        code: "75",
        nameTh: "สมุทรสงคราม",
        nameEn: "Samut Songkhram",
      },
      {
        id: 60,
        regionId: 2,
        code: "74",
        nameTh: "สมุทรสาคร",
        nameEn: "Samut Sakhon",
      },
      {
        id: 18,
        regionId: 5,
        code: "27",
        nameTh: "สระแก้ว",
        nameEn: "Sa kaeo",
      },
      {
        id: 10,
        regionId: 2,
        code: "19",
        nameTh: "สระบุรี",
        nameEn: "Saraburi",
      },
      {
        id: 8,
        regionId: 2,
        code: "17",
        nameTh: "สิงห์บุรี",
        nameEn: "Sing Buri",
      },
      {
        id: 52,
        regionId: 2,
        code: "64",
        nameTh: "สุโขทัย",
        nameEn: "Sukhothai",
      },
      {
        id: 58,
        regionId: 2,
        code: "72",
        nameTh: "สุพรรณบุรี",
        nameEn: "Suphan Buri",
      },
      {
        id: 68,
        regionId: 6,
        code: "84",
        nameTh: "สุราษฎร์ธานี",
        nameEn: "Surat Thani",
      },
      {
        id: 21,
        regionId: 3,
        code: "32",
        nameTh: "สุรินทร์",
        nameEn: "Surin",
      },
      {
        id: 32,
        regionId: 3,
        code: "43",
        nameTh: "หนองคาย",
        nameEn: "Nong Khai",
      },
      {
        id: 28,
        regionId: 3,
        code: "39",
        nameTh: "หนองบัวลำภู",
        nameEn: "Nong Bua Lam Phu",
      },
      {
        id: 6,
        regionId: 2,
        code: "15",
        nameTh: "อ่างทอง",
        nameEn: "Ang Thong",
      },
      {
        id: 26,
        regionId: 3,
        code: "37",
        nameTh: "อำนาจเจริญ",
        nameEn: "Amnat Charoen",
      },
      {
        id: 30,
        regionId: 3,
        code: "41",
        nameTh: "อุดรธานี",
        nameEn: "Udon Thani",
      },
      {
        id: 42,
        regionId: 1,
        code: "53",
        nameTh: "อุตรดิตถ์",
        nameEn: "Uttaradit",
      },
      {
        id: 49,
        regionId: 2,
        code: "61",
        nameTh: "อุทัยธานี",
        nameEn: "Uthai Thani",
      },
      {
        id: 23,
        regionId: 3,
        code: "34",
        nameTh: "อุบลราชธานี",
        nameEn: "Ubon Ratchathani",
      },
    ],
    transactionStatus: [
      {
        code: "P",
        name: "Pending",
      },
      {
        code: "CC",
        name: "Cancel",
      },
      {
        code: "WP",
        name: "Waiting for Payment",
      },
      {
        code: "PC",
        name: "Payment Cancel",
      },
      {
        code: "C",
        name: "Complete",
      },
      {
        code: "V",
        name: "Void",
      },
    ],
  },
  mutations: {
    setCustomerId(state, payload) {
      state.customerId = payload;
    },
    setCartItems(state, payload) {
      state.cartItems = payload;
    },
    setStatusGuest(state, payload) {
      state.statusGuest = payload;
    },
  },
  actions: {
    sendMessage({ state }, message) {
      var vm = this;
      return new Promise(async (resolve) => {
        var url = state.serverUrl + "zwiz-pushmsg-webbrowser";
        var data = {
          pageid: state.pageid,
          //   pageid: state.pageid,
          userid: state.userid,
          message,
        };
        if (await window.liff.isInClient()) {
          try {
            await window.liff.sendMessages([
              {
                text: message,
                type: "text",
              },
            ]);
            resolve();
          } catch (error) {
            alert(JSON.stringify(error));
            alert(JSON.stringify(error.message));
          }
        } else {
          axios
            .post(url, data, {
              headers: {
                AUTHORIZATION: "Bearer xxx xxx",
              },
            })
            .then(async (response) => {
              if (response.data.responsecode == 200) {
                resolve();
              } else {
                if (response.data.reason) {
                  await Swal.fire({
                    text: response.data.reason,
                  });
                  resolve();
                }
              }
            });
        }
      });
    },
    messengerClose() {
      MessengerExtensions.requestCloseBrowser(
        function success() {},
        function error() {
          window.close();
        }
      );
    },
  },
  modules: {},
});
