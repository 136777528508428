import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en';

// loop over all rules
for (let rule in rules) {
    extend(rule, {
        ...rules[rule], // add the rule
        message: en.messages[rule] // add its message
    });
}

extend('thai_phonenumber',
{
	message: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
	validate: (value) => {
		var pattern = new RegExp(
			/^[0][3-9][0-9]{4}[0-9]{4}$/
	) // fragment locator
	return pattern.test(value)
	}
})