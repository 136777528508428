import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css'
import "./vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from 'sweetalert2'
import { SimpleSVG } from 'vue-simple-svg'
import LoadScript from 'vue-plugin-load-script';



// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('vue-simple-svg', SimpleSVG)
Vue.use(LoadScript);

Vue.component('slick', Slick)
Vue.filter('sizeFilter', function (value) {
  switch (value) {
    case "01":
      return "S";
      break;
    case "02":
      return "M";
      break;
    case "03":
      return "L";
      break;
    case "04":
      return "XL";
      break;

    default:
      return value;
      break;
  }
})
Vue.prototype.$liff = window.liff
Vue.prototype.$Swal = Swal

Vue.config.productionTip = false

new Vue({
  router,
  created() {
    return (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.com/en_US/messenger.Extensions.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'Messenger'));
  },
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
