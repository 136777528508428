<template>
  <div>
    <div class="header">
      <div class="coverempty"></div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss">
  .header {
    height: 50px;
    padding: 0px;
    background-color: #cd999d;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .coverempty {
    height: 100%;
    background-image: url("../assets/logo.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
</style>