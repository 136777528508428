<template>
  <div style="height: 100vh; padding-top: 50px; padding-bottom: 50px; overflow-y: auto; position: relative;">
    <Header></Header>
    <div style="position: fixed; right: 20px; top: 12px; z-index: 1; ">
      <v-btn
        color="grey lighten-2"
        depressed
        @click="openCartSummary"
        style="font-family: 'Dosis', sans-serif; "
        class="py-3"
        icon
        ripple
      >
        <v-badge :color="cartItem > 0 ? '#000' : 'rgba(255,255,255,0)'">
          <template v-slot:badge v-if="cartItem > 0">
            <span class="white--text">{{ cartItem }}</span>
          </template>
          <v-icon large class="mr-2" color="black">mdi-cart-outline</v-icon>
          <!-- <span>สรุปตะกร้าสินค้า</span> -->
        </v-badge>
      </v-btn>
    </div>
    <div class="pt-3">
      <v-tabs
        v-model="selectedCategory"
        show-arrows
        center-active
        centered
        color="#000"
        @change="onChangeCategory"
      >
        <v-tabs-slider color="#cd999d"></v-tabs-slider>
        <template v-for="(category, cindex) in allCategoryList">
          <!-- {{ category.id }} -->
          <v-tab
            show-arrows
            :key="'category_' + cindex"
            :href="'#' + category.slug"
            ripple
            >{{ category.name }}</v-tab
          >
        </template>
      </v-tabs>
    </div>
    <div>
    <v-container>
      <div v-if="loading" style="text-align: center; margin-top: 60px">
        <v-progress-circular
          indeterminate
          color="black"
          size="100"
          :width="7"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-layout row>
          <template v-if="allProductsList.length > 0">
            <template v-for="(product, pindex) in allProductsList">
              <v-flex xs6 sm3 :key="pindex" class="pa-2">
                <v-card class="pa-2" @click="showProductDetails(product.slug)">
                  <div>
                    <!-- :lazy-src="`../../assets/img/products/default-photo.png`" -->
                    <v-img
                      :src="product.image ? product.image : require('../assets/img/placeholder.jpg')"
                      aspect-ratio="1"
                      class="white"
                    ></v-img> 
                  </div>
                  <div style="height: 80px;">
                    {{ product.name }}
                  </div>
                  <div class="text-right">
                    {{ product.totalAmount | numFormat}}
                  </div>
                </v-card>
              </v-flex>
            </template>
            <v-flex xs12>
              <div class="mt-5 d-flex xs12 mx-auto">
                <v-pagination
                  :total-visible="6"
                  v-model="pageNumber"
                  @input="changePage"
                  :length="pagelength"
                ></v-pagination>
              </div>
            </v-flex>
          </template>
          <template v-else>
            <v-flex xs12 class="text-center" style="padding-top: 80px;">
              <span style="font-family: 'Dosis', san-serif; font-size: 48px;">Product not found</span>
            </v-flex>
          </template>
        </v-layout>
      </div>
    </v-container>
      <!-- {{ allProductsList }} -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import queryString from "query-string";
import Header from "@/components/Header.vue";
export default {
  components: {
    Header
  },
  data() {
    return {
      pagination: { rowsPerPage: -1, sortBy: '' },
      total: 0,
      offset: 0,
      pagetmp: 1,
      pageNumber: 1,
      limit: 12,
      allCategoryList: [],
      allProductsList: [],
      slickCategories: {
        slidesToShow: 2,
        variableWidth: true,
        infinite: false,
        arrows: false,
      },
      selectedCategory: '',
      loading: false,
      onChangePage: false,
    }
  },
  computed: {
    cartItem() {
      return this.$store.state.cartItems.length;
    },
    pagelength() {
      if (this.total) {
        var total = parseFloat(this.total)
        return Math.ceil(total / this.limit)
      } else {
        return 1
      }
    },
    baseAPI() {
      return this.$store.state.oandbBaseAPI
    }
  },
  async mounted() {
    var vm = this;
    
    vm.allCategoryList = await vm.$store.state.allCategoryList;

    if(vm.allCategoryList.length == 0) {
      vm.getCategories();
    }

    const query = vm.$route.query;
    if(this.$route.query.cate) {
      this.$store.state.previousCategory = {
        categorySlug: this.$route.query.cate,
        pageNumber: 1
      }
      delete query.cate;
    }

    if(this.$store.state.previousCategory) {
      this.selectedCategory = this.$store.state.previousCategory.categorySlug;
      this.pageNumber = this.$store.state.previousCategory.pageNumber;
    }
    
    
    this.getAllProducts();
  },
  methods: {
    async getCategories() {
      var vm = this;
      let baseAPI = this.baseAPI;
      await axios
        .get(`${baseAPI}inventory/v1/product/models/groups/filter?query=all-products&langId=1&storeWebsiteId=1`)
        .then(response => { 
          if(response.status == 200) {
            vm.allCategoryList = response.data.data.filter.displayCates;
          }
        });
    },
    getAllProducts() {
      var vm = this;
      var page = vm.pageNumber;
      var limit = vm.limit;
      var category = '';


      if(vm.selectedCategory) {
        // category = "&cate="+vm.selectedCategory;
        category = vm.selectedCategory;
      } else {
        category = 'all-products'
      }

      vm.loading = true;
      let baseAPI = this.baseAPI;
      axios
        .get(`${baseAPI}inventory/v1/product/models/groups?query=${category}&langId=1&storeWebsiteId=1&page=${page}&limit=${limit}&order=1`)
        .then(response => { 
          if(response.status == 200) {
            vm.allProductsList = response.data.data.items;
            // vm.allCategoryList = response.data.data.filter.displayCates;
            vm.total = response.data.totalItems;
            vm.loading = false;

          }
        }).catch(error => {
          console.log(error)
          return
        });
    },
    showProductDetails(slug) {
      var vm = this;
      vm.$router.push({
        path: 'product',
        query: {...vm.$route.query, product: slug } ,
      }).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    onChangeCategory() {
      // console.log(this.selectedCategory);

      if(!this.onChangePage) {
        this.$store.state.previousCategory.categorySlug = this.selectedCategory;
        this.pageNumber = 1;
        this.$store.state.previousCategory.pageNumber = 1;
      }

      this.page = '';
      this.pagetmp = '';
      this.allProductsList = [];
      this.getAllProducts();
      this.onChangePage = false;
    },
    changePage(val) {
      var vm = this
      if (this.pagetmp == val) {
        //dont do shit
      } else {
        this.pagetmp = val
        this.$store.state.previousCategory.pageNumber = val
        // vm.offset = vm.pageNumber * this.limit - this.limit
        // if (vm.pageNumber != 1) {
        //   vm.offset + 1
        // }
        this.onChangePage = true;
        this.onChangeCategory()
      }
    },
    openCartSummary() {
      var vm = this;
      const query = vm.$route.query;
      delete query.product;

      vm.$router.push({
        path: 'cart-summary',
        query: {...vm.$route.query }
      }).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
  }, //End methods     
  filters: {
    numFormat: function (value) {
      return new Intl.NumberFormat("th-TH", {
        style: "currency",
        currency: "THB",
      }).format(value);
    },
  }
}
</script>

<style lang="scss" scoped>
.category {
  border: 1px solid #aaaaaa;
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 8px;
  height: 55px;
  position: relative;
  transition: 0.15s ease-in-out;
}

</style>