<template>
  <div
    style="
      height: 100vh;
      padding-top: 50px;
      padding-bottom: 50px;
      overflow-y: auto;
    "
  >
    <Header></Header>
    <div v-if="loading" style="text-align: center; margin-top: 60px">
      <v-progress-circular
        indeterminate
        color="black"
        size="100"
        :width="7"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div style="font-family: 'Dosis', sans-serif">
        <div v-if="stage == 0">
          <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
            <v-container style="padding-bottom: 74px">
              <h1
                style="margin-left: 0.6em; margin-left: 0px; margin-bottom: 8px"
              >
                CHECKOUT
              </h1>
              <div class="checkout">
                <div
                  style="
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 15px;
                  "
                >
                  <div>
                    <v-icon style="font-size: 40px"
                      >mdi-truck-delivery-outline</v-icon
                    >
                  </div>
                  <div class="cotitle">กรอกที่อยู่จัดส่ง</div>
                </div>
                <div>
                  <label class="colabel">
                    ชื่อจริง
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="ชื่อจริง"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="input.first_name"
                      color="black"
                      outlined
                      :error-messages="errors"
                      dense
                      placeholder="ชื่อจริง"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
                <div>
                  <label class="colabel">
                    นามสกุล
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="นามสกุล"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="input.last_name"
                      color="black"
                      :error-messages="errors"
                      outlined
                      dense
                      placeholder="นามสกุล"
                    ></v-text-field>
                  </ValidationProvider>
                </div>

                <!-- <div>
                  <ValidationProvider
                    name="ประเทศ"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                  <label class="colabel">
                    ประเทศ
                    <span style="color: red">*</span>
                  </label>
                  <v-select
                    v-model="input.country"
                    :items="country"
                    :error-messages="errors"
                    placeholder="ประเทศ"
                    style="margin-bottom: 0 !important;"
                    dense
                    outlined
                    item-text="nameTh"
                    item-value="id"
                  ></v-select>
                  </ValidationProvider>
                </div> -->
                <div>
                  <ValidationProvider
                    name="จังหวัด"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <label class="colabel">
                      จังหวัด
                      <span style="color: red">*</span>
                    </label>
                    <v-select
                      v-model="input.province"
                      :items="province"
                      :error-messages="errors"
                      placeholder="จังหวัด"
                      style="margin-bottom: 0 !important"
                      dense
                      outlined
                      item-text="nameTh"
                      return-object
                      @change="onGetDistrict($event)"
                    ></v-select>
                  </ValidationProvider>
                </div>
                <div>
                  <ValidationProvider
                    name="อำเภอ"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <label class="colabel">
                      อำเภอ
                      <span style="color: red">*</span>
                    </label>
                    <v-select
                      :loading="loadingDistrict"
                      v-model="input.district"
                      :items="districtList"
                      dense
                      outlined
                      :error-messages="errors"
                      placeholder="อำเภอ"
                      item-text="nameTh"
                      return-object
                      @change="onGetSubDistrict($event)"
                    ></v-select>
                  </ValidationProvider>
                </div>
                <div>
                  <ValidationProvider
                    name="ตำบล"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <label class="colabel">
                      ตำบล
                      <span style="color: red">*</span>
                    </label>
                    <v-select
                      v-model="input.subDistrict"
                      :items="subDistrictList"
                      :error-messages="errors"
                      placeholder="ตำบล"
                      style="margin-bottom: 0 !important"
                      dense
                      outlined
                      item-text="nameTh"
                      return-object
                    ></v-select>
                  </ValidationProvider>
                </div>
                <label class="colabel">
                  ที่อยู่
                  <span style="color: red">*</span>
                </label>
                <ValidationProvider
                  name="ที่อยู่"
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="input.address_1"
                    placeholder="ที่อยู่"
                    color="black"
                    :error-messages="errors"
                    outlined
                    dense
                  ></v-text-field>
                </ValidationProvider>

                <label class="colabel">
                  รหัสไปรษณีย์
                  <span style="color: red">*</span>
                </label>
                <ValidationProvider
                  name="รหัสไปรษณีย์"
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                >
                  <!-- <ThaiAddressInput
                    @select="onSelectAddress"
                    v-model="input.postcode"
                    :color="'black'"
                    :type="'zipcode'"
                    :error-messages="errors"
                    :texttype="'number'"
                  ></ThaiAddressInput> -->
                  <v-text-field
                    v-model="input.postcode"
                    color="black"
                    :error-messages="errors"
                    placeholder="รหัสไปรษณีย์"
                    outlined
                    type="number"
                    dense
                    maxlength="5"
                    onKeyPress="if(this.value.length==5) return false;"
                  ></v-text-field>
                </ValidationProvider>
                <div>
                  <label class="colabel">
                    เบอร์โทรศัพท์
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="เบอร์โทรศัพท์"
                    :rules="{
                      required: true,
                      min: 9,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="input.phone"
                      type="number"
                      color="black"
                      maxlength="10"
                      :error-messages="errors"
                      placeholder="เบอร์โทรศัพท์"
                      outlined
                      dense
                      onKeyPress="if(this.value.length==10) return false;"
                    ></v-text-field>
                  </ValidationProvider>
                </div>

                <label class="colabel">หมายเหตุ (ถ้ามี)</label>
                <v-textarea
                  v-model="input.customer_note"
                  color="black"
                  outlined
                  dense
                ></v-textarea>
              </div>

              <!-- Payment Type -->
              <v-layout wrap>
                <v-flex xs12 class="my-3">
                  <div style="font-weight: 600; color: black">
                    ตัวเลือกในการชำระเงิน
                  </div>
                </v-flex>
                <v-flex
                  xs6
                  sm4
                  v-for="(item, index) in paymentList"
                  :key="index"
                  class="pa-2"
                >
                  <div
                    :style="
                      paymentActive.value == item.value
                        ? 'background-color: #cd999d; color: white;'
                        : ''
                    "
                    style="border-radius: 15px; cursor: pointer;  height: 100px; vertical font-family: 'Dosis', sans-serif; display: flex; flex-flow: column; justify-content: center; border: 1px solid #aaaaaa;"
                    @click="onSelectedPayment(item)"
                    class="text-center pa-2"
                  >
                    <div>{{ item.text }}</div>
                  </div>
                </v-flex>
              </v-layout>

              <!-- Shipping Type -->
              <v-layout wrap v-if="paymentActive">
                <v-flex xs12 class="my-3">
                  <div style="font-weight: 600; color: black">
                    ตัวเลือกในการจัดส่ง
                  </div>
                </v-flex>
                <template v-for="(item, index) in shippingList">
                  <v-flex
                    xs6
                    sm4
                    :key="index"
                    class="pa-2"
                    v-if="
                      item.status == 'active' &&
                      paymentActive.shipping == item.shippingType
                    "
                  >
                    <div
                      :style="
                        shippingActive == item.id
                          ? 'background-color: #cd999d; color: white;'
                          : ''
                      "
                      style="border-radius: 15px; cursor: pointer;  height: 100px; vertical font-family: 'Dosis', sans-serif; display: flex; flex-flow: column; justify-content: center; border: 1px solid #aaaaaa;"
                      @click="onGetShippingDetailsById(item.id)"
                      class="text-center pa-2"
                    >
                      <span>{{ item.nameTh }}</span>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
              <div v-if="shippingListData" class="pt-2">
                <div>
                  <div style="font-weight: 700">
                    {{ shippingListData.nameTh }}
                  </div>
                  <div>{{ shippingListData.remarkTh }}</div>
                </div>
                <div v-if="shippingListData.shippingRates">
                  <span style="font-weight: 700">ค่าบริการจัดส่ง</span>
                  {{ shippingListData.shippingRates[0].rate }} บาท
                </div>
              </div>
            </v-container>
            <!-- <div style="position:fixed; bottom: 0;width: 100%;">
              <v-btn
                style="font-weight: 600;font-size: 20px;color: white"
                tile
                x-large
                :color="invalid?'#dbdbdb':'black'"
                block
                @click="next(invalid)"
              >Next</v-btn>
            </div>-->
            <div style="position: fixed; bottom: 0; width: 100%">
              <v-btn
                style="font-weight: 600; font-size: 20px; color: white"
                tile
                x-large
                :loading="checkoutLoading"
                :disabled="invalid || shippingActive == '' || checkoutLoading"
                :color="invalid || shippingActive == '' ? '#dbdbdb' : 'black'"
                block
                id="checkout-button"
                @click="checkOut"
                >Checkout</v-btn
              >
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!-- Modal Bank Transfer -->
    <v-dialog width="350px" v-model="showModalBanktransfer" persistent>
      <v-card class="pa-4">
        <div class="text-right">
          <v-btn @click="showModalBanktransfer = false" icon ripple>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <template v-if="onImageLoading">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                class="my-6"
                color="primary"
                size="32"
              ></v-progress-circular>
            </div>
          </template>
          <img
            @load="onImgLoad"
            v-show="!onImageLoading"
            :src="require('../assets/oandb-qrcode.jpeg')"
            width="100%"
            height="auto"
          />
        </div>
        <div class="my-4">
          <span style="font-weight: 700">ยอดเงินที่ต้องชำระ: </span>
          <span class="pl-2">{{ totalPrice | numFormat }}</span>
        </div>
        <div class="text-center mt-2">
          <v-btn @click="onConfirmPaymentTransfer" class="black" dark ripple>
            <span>แจ้งการโอนเงิน</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Modal form confirm payment  -->
    <v-dialog width="500px" v-model="showModalConfirmPayment" persistent>
      <v-card class="pa-4">
        <div class="text-right">
          <v-btn @click="showModalConfirmPayment = false" icon ripple>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <ConfirmPayment
            :orderNo="orderNo"
            :totalAmount="totalPrice"
            @success="onSuccessConfirmPayment"
          ></ConfirmPayment>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import country from "@/assets/country.js";
import { validator } from "vee-validate";
import axios from "axios";
import queryString from "query-string";
// import cartEmpty from "@/components/cartEmpty.vue";
import ThaiAddressInput from "@/components/ThaiAddressInput.vue";
import Header from "@/components/Header.vue";
import ConfirmPayment from "./ConfirmPayment.vue";

export default {
  data() {
    return {
      checkoutLoading: false,
      cart: "",
      totalPrice: 0,
      orderNo: "",
      promodata: [],
      fbpaystatus: false,
      totalpricepromo: "",
      tempAddress: "",
      stage: 0,
      query: "",
      loading: true,
      stocks: [],
      serverUrl: process.env.VUE_APP_API_URL,
      loadingShipping: true,
      shippingList: [],
      shippingActive: "",
      paymentActive: "",
      paymentList: [
        {
          text: "บัตรเครดิต",
          value: "credit-card",
          shipping: "standard",
        },
        {
          text: "โอนเงินผ่านธนาคาร",
          value: "bank-transfer",
          shipping: "standard",
        },
        {
          text: "เก็บเงินปลายทาง",
          value: "COD",
          shipping: "COD",
        },
      ],
      input: {
        first_name: "",
        last_name: "",
        customer_note: "",
        country: "1",
        address_1: "",
        province: "",
        district: "",
        subDistrict: "",
        state: "กรุงเทพมหานคร",
        postcode: "",
        phone: "",
      },
      districtList: [],
      subDistrictList: [],
      loadingDistrict: false,
      loadingSubDistrict: false,
      shippingListData: [],
      omiseCard: "",
      customer: [],
      cartAmount: "",
      showModalBanktransfer: false,
      onImageLoading: true,
      showModalConfirmPayment: false,
      isSkuTransportation: null,
      trackingNo: null,
    };
  },
  components: {
    // cartEmpty,
    ThaiAddressInput,
    Header,
    ConfirmPayment,
  },
  computed: {
    country() {
      return this.$store.state.country;
    },
    province() {
      return this.$store.state.province;
    },
    baseAPI() {
      return this.$store.state.oandbBaseAPI;
    },
  },
  async mounted() {
    this.queryinit();
    this.checkMember();

    // Load omise lib
    await this.$loadScript("https://cdn.omise.co/omise.js")
      .then(() => {
        this.omiseCard = window.OmiseCard;
        this.initConfigCard();
      })
      .catch((error) => {
        comsole.log(error);
      });

    await this.getCart();
    await this.getShippingList();
  },
  methods: {
    initConfigCard() {
      var OmiseCard = this.omiseCard;

      OmiseCard.configure({
        publicKey: "pkey_5qgtpus6d4lv2zgp052", // oandb omise public key
        currency: "THB",
        frameLabel: "O&B",
        submitLabel: "PAY NOW",
        defaultPaymentMethod: "credit_card",
        otherPaymentMethods: [""],
      });
    },
    getShippingList() {
      var vm = this;
      var customerId = vm.query.customerid;
      let baseAPI = vm.baseAPI;
      let postcode = vm.input.postcode;

      axios
        // .get(`${baseAPI}shipping/v1/shippings/search`)
        .get(
          `${baseAPI}shipping/v1/shippings/rates?countryCode=TH&shippingTotal=3&storeWebsiteId=1`
        )
        .then((response) => {
          if (response.status == 200) {
            vm.shippingList = response.data.data;
            vm.loadingShipping = false;
          }
        })
        .catch((error) => {
          if (error.response.data.statusCode == 400) {
            vm.$router
              .push({
                path: "cart-empty",
                query: { ...vm.$route.query, customerid: customerId },
              })
              .catch((error) => {
                if (error.name != "NavigationDuplicated") {
                  throw error;
                }
              });
          }
        });
    },
    onGetShippingDetailsById(id) {
      var vm = this;
      vm.shippingActive = id;
      let baseAPI = vm.baseAPI;
      axios
        .get(`${baseAPI}shipping/v1/shippings/get/${id}`)
        .then((response) => {
          if (response.status == 200) {
            vm.shippingListData = response.data.data;
          }
        })
        .catch((error) => {});
    },

    next(invalid) {
      if (!invalid) {
        this.stage = 1;
      }
    },
    queryinit() {
      var vm = this;
      vm.query = vm.$route.query["liff.state"]
        ? queryString.parse(vm.$route.query["liff.state"])
        : vm.$route.query;

      if (vm.query.pageid) vm.pageid = vm.query.pageid;
    },
    onGetDistrict(province) {
      var vm = this;
      let baseAPI = vm.baseAPI;
      let provinceId = province.id;
      vm.districtList = [];
      vm.subDistrictList = [];
      vm.input.district = "";
      vm.input.subDistrict = "";

      axios
        .get(
          `${baseAPI}master/v1/addresses/districts/search/provinceId?query=${provinceId}`
        )
        .then((response) => {
          vm.loadingDistrict = true;
          if (response.status == 200) {
            vm.districtList = response.data.data;
            vm.loadingDistrict = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onGetSubDistrict(district) {
      var vm = this;
      let baseAPI = vm.baseAPI;
      let districtId = district.id;
      vm.input.subDistrict = "";
      vm.subDistrictList = [];

      axios
        .get(
          `${baseAPI}master/v1/addresses/sub-districts/search/districtId?query=${districtId}`
        )
        .then((response) => {
          vm.loadingSubDistrict = true;
          if (response.status == 200) {
            vm.subDistrictList = response.data.data;
            vm.loadingSubDistrict = false;
          }
        })
        .catch((error) => {});
    },

    getCart() {
      var vm = this;
      let baseAPI = vm.baseAPI;
      var customerId = vm.query.customerid;
      axios
        .get(`${baseAPI}transaction/v1/carts/customer?query=${customerId}`)
        .then((response) => {
          if (
            response.data.message == "Success" &&
            response.data.data.length > 0
          ) {
            var responseCartItem = response.data.data[0].transactionItems;

            responseCartItem.find((item) => {
              if (item.product.name == "Transportation Fee") {
                // console.log(item.product.code)
                this.isSkuTransportation = item.product.code;
                // return item.product.code
              }
            });

            if (vm.isSkuTransportation) {
              vm.removeTransportationFee(customerId);
            } else {
              vm.$store.commit("setCartItems", responseCartItem);
              vm.cart = vm.$store.state.cartItems;
              vm.customer = response.data.data[0].customer;
              vm.orderNo = response.data.data[0].orderNo;
              vm.totalPrice = response.data.data[0].totalAmount;
              vm.loading = false;
            }
          } else {
            vm.$router
              .push({
                path: "cart-empty",
                query: { ...vm.$route.query, customerid: customerId },
              })
              .catch((error) => {
                if (error.name != "NavigationDuplicated") {
                  throw error;
                }
              });
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    // onSelectAddress(address) {
    //   this.input.district = address.district;
    //   this.input.city = address.province;
    //   this.input.province = address.province;
    //   this.input.amphoe = address.amphoe;
    //   this.input.postcode = address.zipcode;
    // },
    async checkOut() {
      var vm = this;
      var customerId = vm.query.customerid;
      var data = {
        shippingId: vm.shippingListData.id,
        transportProviderId: vm.shippingListData.transportProviderId,
        shippingRateId: vm.shippingListData.shippingRates[0].id,
        shippingAmount: vm.shippingListData.shippingRates[0].rate,
        taxId: "",
        name: "",
        lastname: "",
        branchType: "",
        branchCode: "",
        branchName: "",
        remark: vm.input.customer_note,
        shippingAddresses: [
          {
            addressType: "S",
            name: vm.input.first_name,
            lastname: vm.input.last_name,
            phoneNo: vm.input.phone,
            line1: vm.input.address_1,
            line2: "",
            subDistrictId: vm.input.subDistrict.id,
            subDistrict: vm.input.subDistrict.nameTh,
            districtId: vm.input.district.id,
            district: vm.input.district.nameTh,
            provinceId: vm.input.province.id,
            province: vm.input.province.nameTh,
            state: "",
            postalCode: vm.input.postcode,
            countryId: 1,
            country: "ประเทศไทย",
            countryCode: "",
          },
        ],
      };

      vm.checkoutLoading = true;
      vm.getCart();

      let baseAPI = vm.baseAPI;
      axios
        .post(`${baseAPI}transaction/v1/cart/shippings?query=${customerId}`, {
          data,
        })
        .then((response) => {
          // console.log(response)
          if (response.data.message == "Success") {
            if (vm.paymentActive.value == "bank-transfer") {
              vm.addPaymentTransfer();
            } else if (vm.paymentActive.value == "COD") {
              vm.addPaymentCod();
            } else if (vm.paymentActive.value == "credit-card") {
              // vm.addPaymentCreditCard();
              vm.saveAddress();
              vm.addTransaction();
            } else {
              vm.$Swal.fire({
                text: "กรุณาตรวจสอบวิธีการชำระเงินอีกครั้ง",
                confirmButtonColor: "black",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    async saveAddress() {
      var vm = this;
      var userid = vm.$route.query.userid;
      var data = {
        pageid: vm.$route.query.pageid,
        address: {
          name: vm.input.first_name,
          lastname: vm.input.last_name,
          phoneNo: vm.input.phone,
          line1: vm.input.address_1,
          subDistrictId: vm.input.subDistrict,
          districtId: vm.input.district,
          provinceId: vm.input.province,
          postalCode: vm.input.postcode,
          countryId: 1,
        },
      };

      axios
        .post(vm.serverUrl + "oab/save/address/" + userid, data, {
          headers: {
            AUTHORIZATION: "Bearer xxx xxx",
          },
        })
        .then((response) => {
          if (response.data.responsecode !== 200) {
            vm.$Swal.fire({
              text: response.data.reason,
              confirmButtonColor: "black",
            });
          }
        });
    },
    async addPaymentCreditCard() {
      var vm = this;
      var customerId = vm.query.customerid;
      var shippingRate = vm.shippingListData.shippingRates[0].rate;
      var total = parseFloat(vm.totalPrice + shippingRate);
      let baseAPI = vm.baseAPI;

      axios
        .post(`${baseAPI}transaction/v1/cart/payments?query=${customerId}`, {
          data: {
            transactionPaymentTypeId: 7, //FIX
            type: "creditCard", //FIX
            amount: total,
            bankId: null,
            account: "",
            installmentMonth: null,
            quantity: null,
            reference: "",
            data: null,
            issuer: "",
            paymentDate: "",
            paymentTime: "",
          },
        })
        .then((response) => {
          if (response.data.message == "Success") {
            vm.saveAddress();
            vm.addTransaction();

            // vm.$Swal.fire({
            //   text: 'แอดมินได้รับหลักฐานการโอนเงินเรียบร้อย รบกวนรอตรวจสอบสักครู่ค่ะ',
            //   confirmButtonColor: "black"
            // }).then((result) => {
            //   if (result.value) {
            //     vm.$liff.closeWindow();
            //   }
            // });

            // vm.addTransaction();
          } else {
            vm.$Swal.fire({
              text: response.data.reason,
              confirmButtonColor: "black",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    async addPaymentCod() {
      var vm = this;
      var customerId = vm.query.customerid;
      var shippingRate = vm.shippingListData.shippingRates[0].rate;
      var total = parseFloat(vm.totalPrice + shippingRate);
      let baseAPI = vm.baseAPI;

      axios
        .post(`${baseAPI}transaction/v1/cart/payments?query=${customerId}`, {
          data: {
            transactionPaymentTypeId: 8, //FIX
            type: "cod", //FIX
            amount: total,
            bankId: null,
            account: "",
            installmentMonth: null,
            quantity: null,
            reference: "COD",
            data: null,
            issuer: "",
            paymentDate: "",
            paymentTime: "",
          },
        })
        .then((response) => {
          if (response.data.message == "Success") {
            vm.saveAddress();
            vm.addTransaction();
          } else {
            vm.$Swal.fire({
              text: response.data.reason,
              confirmButtonColor: "black",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    async addPaymentTransfer() {
      var vm = this;
      var customerId = vm.query.customerid;
      var shippingRate = vm.shippingListData.shippingRates[0].rate;
      var total = parseFloat(vm.totalPrice + shippingRate);
      let baseAPI = vm.baseAPI;

      axios
        .post(`${baseAPI}transaction/v1/cart/payments?query=${customerId}`, {
          data: {
            transactionPaymentTypeId: 2, //FIX
            type: "transfer", //FIX
            amount: total,
            bankId: 1,
            account: "",
            installmentMonth: null,
            quantity: null,
            reference: "",
            data: null,
            issuer: "",
            paymentDate: "",
            paymentTime: "",
          },
        })
        .then((response) => {
          if (response.data.message == "Success") {
            vm.saveAddress();
            vm.addTransaction();

            // vm.$Swal.fire({
            //   text: 'แอดมินได้รับหลักฐานการโอนเงินเรียบร้อย รบกวนรอตรวจสอบสักครู่ค่ะ',
            //   confirmButtonColor: "black"
            // }).then((result) => {
            //   if (result.value) {
            //     vm.$liff.closeWindow();
            //   }
            // });

            // vm.addTransaction();
          } else {
            vm.$Swal.fire({
              text: response.data.reason,
              confirmButtonColor: "black",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    async addTransaction() {
      var vm = this;
      var customerId = vm.query.customerid;
      let baseAPI = vm.baseAPI;

      axios
        .post(`${baseAPI}transaction/v1/transactions/order`, {
          data: {
            customerCode: customerId,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.message == "Success") {
            if (vm.paymentActive.value == "bank-transfer") {
              vm.showModalBanktransfer = true;
              vm.checkoutLoading = false;
            } else if (vm.paymentActive.value == "COD") {
              vm.onPaymentCodConfirm();
            } else if (vm.paymentActive.value == "credit-card") {
              var OmiseCard = vm.omiseCard;
              OmiseCard.configureButton("#checkout-button");
              OmiseCard.attach();
              vm.stage = 1;
              vm.omiseHandler();
            } else {
              vm.$Swal.fire({
                text: response.data.reason,
                confirmButtonColor: "black",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    omiseHandler() {
      var OmiseCard = this.omiseCard;
      OmiseCard.open({
        amount: this.totalPrice * 100,
        onCreateTokenSuccess: (token) => {
          this.onOmiseCheckout(token);
        },
        // onFormClosed: () => {
        //   /* Handler on form closure. */
        // },
      });
    },
    onOmiseCheckout(token = null) {
      var vm = this;
      var shippingRate = vm.shippingListData.shippingRates[0].rate;
      var total = parseFloat(vm.totalPrice + shippingRate);

      var data = {
        userid: vm.query.userid,
        pageid: vm.query.pageid,
        email: vm.customer.person.email,
        name: vm.customer.person.fullName,
        amount: vm.totalPrice,
        shippingPrice: shippingRate,
        total: total,
        token: token,
        customerCode: vm.customer.code,
        orderNo: vm.orderNo,
        trackingNumber: vm.trackingNo,
      };
      vm.checkoutLoading = true;

      // console.log(data);

      axios
        .post(vm.serverUrl + "oab/v1/checkout", data, {
          headers: {
            AUTHORIZATION: "Bearer xxx xxx",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            vm.checkoutLoading = false;
            window.location.href = response.data.redirect_url;
          }
          /*
          if (response.data.responsecode == 200) {
            vm.checkoutLoading = false;
            vm.$store.commit("setCartItems", {});

            vm.$Swal
              .fire({
                text: "Payment Successfully.",
                confirmButtonColor: "black",
              })
              .then((result) => {
                if (result.value) {
                  if (vm.query.apptype == "line") {
                    vm.$liff.closeWindow();
                  } else if (vm.query.apptype == "facebook") {
                    MessengerExtensions.requestCloseBrowser(
                      function success() {},
                      function error() {
                        window.close();
                      }
                    );
                  }
                }
              });
            
          }
          */
        });
    },
    async checkMember() {
      var vm = this;
      var userid = vm.$store.state.userid;
      var pageid = vm.query.pageid;

      var url = "v2/oab/customer/checkcustomer/" + userid + "?pageid=" + pageid;
      axios
        .get(vm.serverUrl + url, {
          headers: { AUTHORIZATION: "Bearer xxx xxx" },
        })
        .then(async (response) => {
          if (response.data.responsecode == 200) {
            if (response.data.data && response.data.data.address) {
              var address = response.data.data.address;

              vm.input.first_name = address.name;
              vm.input.last_name = address.lastname;
              vm.input.phone = address.phoneNo;
              vm.input.address_1 = address.line1;
              vm.input.postcode = address.postalCode;
              vm.input.province = address.provinceId;

              vm.onGetDistrict(address.provinceId);
              vm.input.district = address.districtId;

              vm.onGetSubDistrict(address.districtId);
              vm.input.subDistrict = address.subDistrictId;
            }
          } else {
            vm.$Swal.fire({
              text: response.data.reason,
              confirmButtonColor: "black",
            });
          }
        });
    },
    async removeTransportationFee(customerId) {
      var vm = this;
      var sku = vm.isSkuTransportation;
      let baseAPI = vm.baseAPI;

      axios
        .delete(
          `${baseAPI}transaction/v1/cart/items?query=${customerId}&sku=${sku}`
        )
        .then((response) => {
          if (response.status == 200) {
            vm.checkOut();
            vm.isSkuTransportation = null;
          }
        });
    },
    async onPaymentCodConfirm() {
      var vm = this;
      var customerId = vm.query.customerid;
      var shippingRate = vm.shippingListData.shippingRates[0].rate;
      var total = parseFloat(vm.totalPrice + shippingRate);
      let baseAPI = vm.baseAPI;

      await axios
        .post(`${baseAPI}payment/v1/payment-cod/confirm`, {
          data: {
            customerCode: customerId,
            amount: total,
          },
        })
        .then((response) => {
          if (response.data.message == "Success") {
            vm.trackingNo =
              response.data.data[0].transactionShipping.trackingNo;
            vm.onOmiseCheckout();
          }
        });
    },
    onSelectedPayment(payload) {
      this.paymentActive = payload;
      this.shippingActive = "";
    },
    onImgLoad() {
      this.onImageLoading = false;
    },
    onConfirmPaymentTransfer() {
      this.showModalBanktransfer = false;
      this.showModalConfirmPayment = true;
    },
    async onSuccessConfirmPayment() {
      var vm = this;
      vm.showModalConfirmPayment = false;
      await vm.onSendMessageConfirmTransfer();

      // vm.$store.dispatch("sendMessage", `ขอบคุณสำหรับการสั่งซื้อค่ะ แอดมินได้รับหลักฐานการโอนเงินของเลขที่ออเดอร์ ${orderNo} เรียบร้อยแล้ว รบกวนรอเจ้าหน้าที่ตรวจสอบสักครู่นะคะ`);

      vm.$store.commit("setCartItems", {});

      if (vm.query.apptype == "line") {
        vm.$liff.closeWindow();
      } else if (vm.query.apptype == "facebook") {
        MessengerExtensions.requestCloseBrowser(
          function success() {},
          function error() {
            window.close();
          }
        );
      }
    },
    async onSendMessageConfirmTransfer() {
      var vm = this;
      let userid = vm.$route.query.userid;
      let pageid = vm.$route.query.pageid;
      let orderNo = vm.orderNo;

      let data = {
        userid: userid,
        pageid: pageid,
        orderNo: orderNo,
      };

      var url = vm.serverUrl + "oab/uploadSlip/sendmessage";
      await axios
        .post(url, data, {
          headers: {
            AUTHORIZATION: "Bearer xxx xxx",
          },
        })
        .then((response) => {
          if (response.data.responsecode !== 200) {
            vm.$Swal.fire({
              text: response.data.reason,
              confirmButtonColor: "black",
            });
          }
        });
    },
  },
  filters: {
    getPromoPrice(id, promodata) {
      var vm = this;
      if (id && promodata.length) {
        var data = promodata
          .filter((el) => el.id == id)
          .map((el) => parseFloat(el.pricepromo))
          .reduce((total, num) => {
            return (total += num);
          });
        return data;
      }
    },
    getPrice(id, promodata) {
      var vm = this;
      if (id && promodata.length) {
        var data = promodata
          .filter((el) => el.id == id)
          .map((el) => parseFloat(el.price))
          .reduce((total, num) => {
            return (total += num);
          });
        return data;
      }
    },
    numFormat: function (value) {
      return new Intl.NumberFormat("th-TH", {
        style: "currency",
        currency: "THB",
      }).format(value);
    },
  },
};
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.priceCheckout {
  text-align: right;
  // font-size: 19px;
  font-weight: 600;
  color: grey;
  position: relative;
}
.priceCartOutoforder {
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}
.priceOutoforder {
  background-color: black;
  border-radius: 39px;
  color: white;
  padding: 0.01em 0.5em;
  z-index: 5;
}
.checkout {
  padding: 1em;
  border-radius: 6px;
  background-color: #f5f4f4;
  font-family: "Sarabun", sans-serif;
}
.colabel {
  color: black;
  font-weight: 600;
}
.cotitle {
  font-size: 18px;
  font-weight: 600;
  color: grey;
  display: flex;
  flex-direction: row;
}
.cotitle:before,
.cotitle:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #cfcfcf;
  margin: 15px;
}
.paymenttype .v-btn__content {
  display: block !important;
  font-family: "Kanit", sans-serif;
  font-size: 15px;
  color: rgb(172, 172, 172);
}
.paymenttype.active {
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
}
.outoforderText {
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: white;
  position: absolute;
  left: 10px;
  top: 10%;
  z-index: 2;
  font-size: 2.5em;
  opacity: 0.6;
}
.stockout {
  font-family: sans-serif;
  position: absolute;
  font-weight: 400;
  bottom: 10px;
  left: 10px;
  color: white;
  background-color: #ff3f3f;
  padding: 1px 10px;
  border-radius: 17px;
}
</style>
