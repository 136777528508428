var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[(!_vm.isForgetPassword)?_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"mt-2 mb-4 text-center"},[_c('h2',[_vm._v("เข้าสู่ระบบ")])]),_c('div',[_c('ValidationObserver',{ref:"observer"},[_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" อีเมล: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"username","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"color":"black","outlined":"","error-messages":errors,"dense":""},model:{value:(_vm.input.username),callback:function ($$v) {_vm.$set(_vm.input, "username", $$v)},expression:"input.username"}})]}}],null,false,1858709702)})],1),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" รหัสผ่าน: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"password","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"type":"password","color":"black","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}})]}}],null,false,833003998)})],1)])],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"font-weight":"600","font-size":"20px","color":"white","height":"48px"},attrs:{"loading":_vm.saveLoading,"dark":"","block":""},on:{"click":_vm.onLogin}},[_vm._v("ยืนยัน")])],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"text":"","ripple":""},on:{"click":_vm.onForgetPassword}},[_c('span',[_vm._v("ลืมรหัสผ่าน")])])],1),_c('div',{staticClass:"mt-4"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"pr-2",attrs:{"xs6":""}},[_c('v-btn',{staticStyle:{"width":"100%","height":"48px"},attrs:{"dark":""},on:{"click":_vm.onLoginAsGuest}},[_c('span',[_vm._v("สั่งซื้อเลย")])])],1),_c('v-flex',{staticClass:"pl-2",attrs:{"xs6":""}},[_c('v-btn',{staticStyle:{"width":"100%","height":"48px"},attrs:{"dark":""},on:{"click":function($event){return _vm.$emit('onRegister')}}},[_c('span',[_vm._v("สมัครสมาชิก")])])],1)],1)],1)]):_c('v-card',{staticClass:"pa-4"},[(!_vm.statusSentEmail)?[_c('div',{staticClass:"mt-2 mb-4"},[_c('h2',[_vm._v("ลืมรหัสผ่าน")])]),_c('ValidationObserver',{ref:"observerForgetPassword"},[_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" อีเมล: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"email","rules":{
                required: true,
                email: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"color":"black","outlined":"","error-messages":errors,"dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,false,452500880)})],1)]),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"font-size":"16px","color":"white","height":"48px"},attrs:{"loading":_vm.saveLoading,"dark":"","block":""},on:{"click":_vm.sendForgetPassword}},[_vm._v("ยืนยัน")]),_c('v-btn',{staticClass:"mt-2",staticStyle:{"font-size":"14px"},attrs:{"text":"","ripple":""},on:{"click":function($event){_vm.isForgetPassword = false}}},[_vm._v("ย้อนกลับ")])],1)]:[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-center"},[_c('v-avatar',{staticStyle:{"border":"4px solid #000"},attrs:{"size":"62","color":"#FFF"}},[_c('v-icon',{attrs:{"dark":"","x-large":"","color":"black"}},[_vm._v(" mdi-check ")])],1)],1),_c('div',{staticClass:"my-6 text-center"},[_vm._v(" If there is an account associated with "),_c('b',[_vm._v(_vm._s(_vm.email))]),_c('br'),_vm._v(" You will receive an email with a link to reset your password. ")])]),_c('div',{staticClass:"mb-3 text-center"},[_c('v-btn',{staticStyle:{"font-size":"16px","color":"white","height":"48px","width":"150px"},attrs:{"loading":_vm.saveLoading,"dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("ตกลง")])],1)]],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }