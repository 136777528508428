<template>
  <div
    style="
      height: 100vh;
      padding-top: 50px;
      padding-bottom: 120px;
      overflow-y: auto;
    "
  >
    <Header></Header>
    <div v-if="loading" style="text-align: center; margin-top: 60px">
      <v-progress-circular
        indeterminate
        color="black"
        size="100"
        :width="7"
      ></v-progress-circular>
    </div>
    <!-- <div v-else-if="!cart.length">
      <cart-empty :apptype="query.apptype"></cart-empty>
    </div> -->
    <div v-else style="font-family: 'Dosis', sans-serif; font-weight: 600">
      <v-container style="margin-bottom: 60px; padding-top: 0">
        <div class="topbar">
          <h1 style="font-family: 'Roboto', sans-serif; margin-left: 0.6em">
            CART
          </h1>
          <div style="margin-right: 0.6em; font-size: 1.5em">
            <div
              style="
                text-decoration-line: line-through;
                font-size: 14px;
                text-align: right;
              "
              class="red--text"
              v-if="totalDiscountAmount > 0"
            >
              {{ totalFullPrice | numFormat }}
            </div>
            <div>{{ totalPrice | numFormat }}</div>
            <div style="text-align: right" class="body-2">
              {{ cartItems.length }} items
            </div>
          </div>
        </div>
        <div class="cart">
          <div v-for="(item, k) in cart" :key="k" class="cartitem">
            <template v-if="item.product.name !== 'Transportation Fee'">
              <v-row style="position: relative">
                <v-col cols="3">
                  <div class="itemphoto">
                    <v-img
                      cover
                      style="border-radius: 7px"
                      :src="item.product.productInformation.primaryImageUrl"
                    ></v-img>
                    <!-- <div style="text-align: center">Size: {{ item.size }}</div> -->
                    <div class="text-center">
                      <span
                        v-if="
                          $options.filters.getPromoPrice(item.id, promodata) <
                            $options.filters.getPrice(item.id, promodata)
                        "
                        style="
                          background-color: black;
                          color: white;
                          color: white;
                          font-size: 0.8em;
                          padding: 3px 12px;
                          border-radius: 12px;
                        "
                        >Promotion</span
                      >
                    </div>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="itemdetail">
                    <div style="margin-bottom: 0.5em; font-weight: 400">
                      {{ item.product.name }}
                    </div>
                    <div>
                      <v-layout>
                        <v-flex xs9>
                          <div class="mb-2">
                            {{ item.amount | numFormat }}
                          </div>
                          <div style="text-align: left">
                            <div>
                              <v-icon
                                @click="
                                  changeQuantity(
                                    'decrease',
                                    item.quantity,
                                    item.product.sku
                                  )
                                "
                                >mdi-minus</v-icon
                              >
                              <span
                                style="margin-left: 1em; margin-right: 1em"
                                >{{ item.quantity }}</span
                              >
                              <v-icon
                                @click="
                                  changeQuantity(
                                    'increase',
                                    item.quantity,
                                    item.product.sku
                                  )
                                "
                                >mdi-plus</v-icon
                              >
                              <!-- <div class="ml-5">Quantity</div> -->
                            </div>
                          </div>
                        </v-flex>
                        <v-flex xs3 class="d-flex justify-end align-end">
                          <div>
                            <v-btn @click="removeItem(item.product.sku)" icon>
                              <v-icon style="color: black"
                                >mdi-trash-can</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- <div
                      v-if="promodata.length"
                      style="
                        text-align: right;
                        font-weight: 600;
                        font-size: 1.3em;
                      "
                    >
                      {{
                        $options.filters.getPromoPrice(item.id, promodata)
                          | numFormat
                      }}
                    </div> -->
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-divider
                v-if="k + 1 !== cart.length"
                style="margin-bottom: 0.5em"
              ></v-divider>
            </template>
          </div>
        </div>
        <div class="text-center mt-5">
          <v-btn @click="backToShop">
            <span>Continue Shopping</span>
          </v-btn>
        </div>
        <!-- Promotion -->
        <div class="mt-3" v-if="promotionList.length > 0">
          <h3 style="font-family: 'Roboto', sans-serif" class="mb-2">
            Promotion
          </h3>
          <template v-for="(item, index) in promotionList">
            <div :key="index">
              <div class="mb-1">{{ item.promotion.name }}</div>
              <div style="font-weight: 400">
                <ul>
                  <li>{{ item.promotion.promotionRules[0].name }}</li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </v-container>
    </div>
    <!-- Coupon -->
    <div
      style="
        position: fixed;
        bottom: 0;
        margin-bottom: 50px;
        width: 100%;
        font-family: 'Dosis', sans-serif;
        background: #fff;
      "
    >
      <v-container>
        <v-layout wrap>
          <v-flex
            xs12
            style="font-family: 'Dosis', sans-serif"
            class="pa-2"
            v-if="statusApplyCoupon"
          >
            <span class="red--text">ส่วนลด:</span>
            <span class="pl-1">{{ couponDiscount }} บาท</span>
          </v-flex>
          <v-flex xs9 class="px-2">
            <v-text-field
              v-model="couponCode"
              clear-icon="mdi-close-circle"
              clearable
              outlined
              dense
              single-line
              placeholder="กรอกคูปองโค๊ด"
              type="text"
              hide-details
              @click:clear="onDeleteCoupon"
            ></v-text-field>
          </v-flex>
          <v-flex xs3 class="px-2">
            <v-btn
              :disabled="statusApplyCoupon"
              style="width: 100%; font-family: 'Dosis', sans-serif"
              @click="onApplyCoupon"
              >ยืนยัน</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <!-- Footer -->
    <div
      class="text-center white--text btn-checkout-footer"
      style="background: #cd999d"
      @click="onCheckout"
    >
      <v-layout>
        <v-flex xs12>
          <div class="d-flex justify-center">
            <v-icon small class="mr-2" color="#FFF"
              >mdi-credit-card-outline
            </v-icon>
            <span>ชำระเงิน</span>
          </div>
        </v-flex>
        <!-- <v-flex xs6>
          <div class="d-flex justify-center">
            <v-icon small class="mr-2" color="#FFF">mdi-cart-outline</v-icon>
            <span>สรุปตะกร้าสินค้า</span>
          </div>
        </v-flex> -->
      </v-layout>
      <!-- <div class="d-flex justify-center">
          <v-icon small class="mr-2" color="#FFF">mdi-cart-outline</v-icon>
          <span>สรุปตะกร้าสินค้า</span>
        </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import queryString from "query-string";
import Header from "@/components/Header.vue";

export default {
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      cart: [],
      totalPrice: "",
      totalDiscountAmount: 0,
      totalFullPrice: 0,
      query: "",
      loading: true,
      cartLoading: false,
      promodata: "",
      qtyItems: 0,
      couponCode: "",
      couponDiscount: 0,
      statusApplyCoupon: false,
      promotionList: [],
      customer: [],
    };
  },
  components: {
    Header,
  },
  async mounted() {
    this.queryinit();
    await this.getCart();
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    baseAPI() {
      return this.$store.state.oandbBaseAPI;
    },
  },
  methods: {
    cancel() {
      this.query.view = this.$store.state.redirect;
    },
    queryinit() {
      var vm = this;
      vm.query = vm.$route.query["liff.state"]
        ? queryString.parse(vm.$route.query["liff.state"])
        : vm.$route.query;

      if (vm.query.pageid) vm.pageid = vm.query.pageid;
    },
    getCart() {
      var vm = this;
      var customerId = vm.query.customerid;
      let baseAPI = this.baseAPI;
      axios
        .get(`${baseAPI}transaction/v1/carts/customer?query=` + customerId)
        .then((response) => {
          // console.log(response);
          if (
            response.data.message == "Success" &&
            response.data.data.length > 0
          ) {
            var responseCartItem = response.data.data[0].transactionItems;
            vm.promotionList = response.data.data[0].transactionPromotions;
            vm.customer = response.data.data[0].customer;
            vm.$store.commit("setCartItems", responseCartItem);
            vm.cart = vm.$store.state.cartItems;
            vm.totalPrice = response.data.data[0].totalAmount;
            vm.totalFullPrice = response.data.data[0].totalFullAmount;
            vm.totalDiscountAmount = response.data.data[0].totalDiscountAmount;

            var cart = vm.cart;
            var sumItems = 0;
            cart.forEach((el) => {
              sumItems += el.quantity;
            });
            vm.qtyItems = sumItems;

            if (vm.customer.level == "guest") {
              vm.loginModal = true;
            }

            vm.loading = false;
          } else {
            vm.$store.commit("setCartItems", {});
            vm.$router
              .push({
                path: "cart-empty",
                query: { ...vm.$route.query, customerid: customerId },
              })
              .catch((error) => {
                if (error.name != "NavigationDuplicated") {
                  throw error;
                }
              });
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    changeQuantity(action, currentQty, sku) {
      var vm = this;
      var customerId = vm.query.customerid;
      // var sku = vm.sizeSelected.sku;
      var quantity = 0;

      if (action == "increase") {
        quantity = parseInt(currentQty + 1);
      } else {
        quantity = parseInt(currentQty - 1);
      }

      if (quantity == 0) {
        vm.removeItem(sku);
      } else {
        let baseAPI = this.baseAPI;
        axios
          .patch(`${baseAPI}transaction/v1/cart/items?query=${customerId}`, {
            data: {
              sku: sku,
              quantity: quantity,
            },
          })
          .then((response) => {
            if (response.status == 200) {
              vm.getCart();
            }
          })
          .catch((error) => {
            if (error.response.data.statusCode == 400) {
              vm.$Swal.fire({
                text: error.response.data.message,
                confirmButtonColor: "black",
              });
            }
          });
      }
    },
    removeItem(sku) {
      var vm = this;
      var customerId = vm.query.customerid;
      vm.$Swal
        .fire({
          text: "Delete this item?",
          showCancelButton: true,
          confirmButtonColor: "black",
        })
        .then((result) => {
          if (result.value) {
            let baseAPI = this.baseAPI;
            axios
              .delete(
                `${baseAPI}transaction/v1/cart/items?query=${customerId}&sku=${sku}`
              )
              .then((response) => {
                if (response.status == 200) {
                  vm.getCart();
                }
              });
          }
        });
    },
    onCheckout() {
      var vm = this;
      vm.$router
        .push({
          path: "checkout",
          query: { ...vm.$route.query },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    backToShop() {
      var vm = this;
      const query = vm.$route.query;
      delete query.product;

      vm.$router
        .push({
          path: "all-products",
          query: { ...vm.$route.query },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    onApplyCoupon() {
      var vm = this;
      var customerId = vm.query.customerid;
      var code = vm.couponCode;
      let baseAPI = this.baseAPI;

      axios
        .post(`${baseAPI}transaction/v1/cart/coupons/use?query=${customerId}`, {
          data: {
            code: code,
          },
        })
        .then((response) => {
          if (response.data.message == "Success") {
            vm.couponDiscount = response.data.data[0].totalDiscountAmount;
            vm.statusApplyCoupon = true;
            vm.getCart();
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    onDeleteCoupon() {
      var vm = this;
      var customerId = vm.query.customerid;
      var code = vm.couponCode;

      if (vm.statusApplyCoupon) {
        vm.statusApplyCoupon = false;
        let baseAPI = this.baseAPI;
        axios
          .delete(
            `${baseAPI}transaction/v1/cart/coupons/use?query=${customerId}&code=${code}`
          )
          .then((response) => {
            if (response.data.message == "Success") {
              vm.couponDiscount = 0;
              vm.getCart();
            }
          })
          .catch((error) => {
            console.log(error);
            return;
          });
      }
    },
  },
  filters: {
    getPromoPrice(id, promodata) {
      var vm = this;
      if (id && promodata.length) {
        var data = promodata
          .filter((el) => el.id == id)
          .map((el) => parseFloat(el.pricepromo))
          .reduce((total, num) => {
            return (total += num);
          });
        return data;
      }
    },
    getPrice(id, promodata) {
      var vm = this;
      if (id && promodata.length) {
        var data = promodata
          .filter((el) => el.id == id)
          .map((el) => parseFloat(el.price))
          .reduce((total, num) => {
            return (total += num);
          });
        return data;
      }
    },
    numFormat: function(value) {
      return new Intl.NumberFormat("th-TH", {
        style: "currency",
        currency: "THB",
      }).format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.priceCartOutoforder {
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}
.cart {
  padding: 1em;
  border-radius: 6px;
  background-color: #f5f4f4;
}
.topbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  position: sticky;
  top: 0px;
  background-color: white;
  border-bottom-width: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  z-index: 5;
}
.itemphoto {
  height: 100%;
  width: 100%;
  display: inline-block;
}
.itemdetail {
  display: inline-block;
  width: 100%;
}
.checkoutbtn {
  color: white;
  // width: 90% !important;
  font-size: 20px;
  font-weight: 600;
}
.stockout {
  font-family: sans-serif;
  position: absolute;
  font-weight: 400;
  bottom: 10px;
  right: 5px;
  color: white;
  background-color: #ff3f3f;
  padding: 1px 10px;
  border-radius: 17px;
}
.cartitem {
  position: relative;
}
.v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
