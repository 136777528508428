<template>
  <div style="font-family: 'Dosis', sans-serif">
    <ValidationObserver ref="observer">
      <v-container style="padding-bottom: 16px">
        <h1 style="margin-left: 0.6em; margin-left: 0px; margin-bottom: 8px">
          CONFIRM PAYMENT
        </h1>
        <div class="checkout">
          <div
            style="
              text-align: center;
              margin-top: 5px;
              margin-bottom: 15px;
            "
          >
            <div>
              <v-icon style="font-size: 40px">mdi-cash-100</v-icon>
            </div>
            <div class="cotitle">แจ้งการโอนเงิน</div>
          </div>
          <div>
            <label class="colabel">
              เลขที่ออเดอร์
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="เลขที่ออเดอร์"
              :rules="{
                required: true,
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                readonly
                v-model="input.orderNumber"
                color="black"
                outlined
                :error-messages="errors"
                dense
                placeholder="เลขที่ออเดอร์"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              จำนวนเงิน
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="จำนวนเงิน"
              :rules="{
                required: true,
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                readonly
                v-model="input.amount"
                color="black"
                :error-messages="errors"
                outlined
                dense
                placeholder="จำนวนเงิน"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              ชื่อ
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="ชื่อ"
              :rules="{
                required: true,
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="input.name"
                color="black"
                :error-messages="errors"
                outlined
                dense
                placeholder="ชื่อ"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              อีเมล
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="อีเมล"
              :rules="'required|email'"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="input.email"
                color="black"
                :error-messages="errors"
                outlined
                dense
                placeholder="อีเมล"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              เบอร์โทรศัพท์
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="phone"
              :rules="{
                required: true,
                min: 9,
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="input.phone"
                type="number"
                color="black"
                maxlength="10"
                :error-messages="errors"
                outlined
                dense
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              วันที่
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="วันที่"
              :rules="{
                required: true,
                min: 9,
              }"
              v-slot="{ errors }"
            >
              <BirthdayPicker v-model="input.transferDate">
                <v-text-field
                  :value="
                    input.transferDate
                      ? moment(input.transferDate)
                          .add(543, 'year')
                          .format('DD/MM/YYYY')
                      : ''
                  "
                  placeholder="DD/MM/YYYY"
                  readonly
                  outlined
                  dense
                  :error-messages="errors"
                ></v-text-field>
              </BirthdayPicker>
            </ValidationProvider>
          </div>
          <div>
            <label class="colabel">
              เวลา
              <span style="color: red">*</span>
            </label>
            <ValidationProvider
              name="เวลา"
              :rules="{
                required: true,
              }"
              v-slot="{ errors }"
            >
              <div>
                <vue-timepicker
                  v-model="input.transferTime"
                  :error-messages="errors"
                  input-class="custom-picker"
                >
                  <!-- <v-text-field
                  :value="input.transferDate"
                  placeholder="12:34"
                  readonly
                  outlined
                  dense
                  :error-messages="errors"
                ></v-text-field> -->
                </vue-timepicker>
              </div>
            </ValidationProvider>
          </div>

          <div class="mt-5">
            <ValidationProvider name="Upload" v-slot="{ errors }">
              <label class="colabel">
                อัปโหลดสลิป
                <span style="color: red">*</span>
              </label>
              <UploadDocument
                v-model="input.slipImage"
                :background="require(`@/assets/icon-upload.png`)"
                :isProfileImage="false"
              >
                <template v-slot="props">
                  <custom-input
                    :loading="props.loading"
                    :value="props.filename"
                    prepend-icon="mdi-attachment"
                    readonly
                    :error-messages="errors"
                  ></custom-input>
                </template>
              </UploadDocument>
            </ValidationProvider>
          </div>

          <div class="mt-5">
            <label class="colabel">หมายเหตุ (ถ้ามี)</label>
            <v-textarea
              v-model="input.remark"
              color="black"
              outlined
              dense
            ></v-textarea>
          </div>
        </div>
      </v-container>
      <div class="text-center mt-2">
        <v-btn
          style="font-size: 16px; color: white; height: 48px;"
          :loading="saveLoading"
          dark
          block
          @click="submitPayment"
          >ยืนยัน</v-btn
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from "axios";
import queryString from "query-string";
import Header from "@/components/Header.vue";
import { validator } from "vee-validate";
import UploadDocument from "@/components/UploadSingleFile.vue";
import BirthdayPicker from "@/components/BirthdayPicker.vue";
import moment from "moment";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: {
    totalAmount: null,
    orderNo: null,
  },
  components: {
    Header,
    UploadDocument,
    BirthdayPicker,
    VueTimepicker,
  },
  data() {
    return {
      input: {
        orderNumber: "",
        amount: "",
        name: "",
        email: "",
        phone: "",
        transferDate: moment().format("YYYY-MM-DD"),
        transferTime: moment().format("HH:mm"),
        slipImage: "",
        remark: "",
      },
      saveLoading: false,
    };
  },
  mounted() {
    this.input.orderNumber = this.orderNo;
    this.input.amount = this.totalAmount;
  },
  computed: {
    moment() {
      return moment;
    },
    baseAPI() {
      return this.$store.state.oandbBaseAPI;
    },
  },
  methods: {
    async submitPayment() {
      const vm = this;
      if (await vm.$refs.observer.validate()) {
        if (vm.input.slipImage == "") {
          vm.$Swal.fire({
            text: "กรุณาอัปโหลดสลิป",
            confirmButtonColor: "black",
          });
          return;
        }

        let data = {
          orderNo: vm.input.orderNumber,
          name: vm.input.name,
          email: vm.input.email,
          phone: vm.input.phone,
          transferSlip: vm.input.slipImage,
          transferDate: vm.input.transferDate,
          transferTime: vm.input.transferTime,
          transferAmount: vm.input.amount,
          bankId: 1,
          remark: vm.input.remark,
        };

        vm.saveLoading = true;

        let baseAPI = vm.baseAPI;

        axios
          .post(`${baseAPI}payment/v1/payment-confirmations/create`, {
            data: data,
          })
          .then(async (response) => {
            if (response.data.message == "Success") {
              this.$emit("success");
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
.custom-picker {
  border-radius: 4px !important;
  border-color: rgba(0, 0, 0, 0.4) !important;
}
</style>
