import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

// export default new Vuetify({
// });


export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#cd999d',
				secondary: '#ffeb3b',
				accent: '#8c9eff',
				error: '#b71c1c',
			},
		},
	},
});