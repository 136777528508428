var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-family":"'Dosis', sans-serif"}},[_c('ValidationObserver',{ref:"observer"},[_c('v-container',{staticStyle:{"padding-bottom":"16px"}},[_c('h1',{staticStyle:{"margin-left":"0px","margin-bottom":"8px"}},[_vm._v(" CONFIRM PAYMENT ")]),_c('div',{staticClass:"checkout"},[_c('div',{staticStyle:{"text-align":"center","margin-top":"5px","margin-bottom":"15px"}},[_c('div',[_c('v-icon',{staticStyle:{"font-size":"40px"}},[_vm._v("mdi-cash-100")])],1),_c('div',{staticClass:"cotitle"},[_vm._v("แจ้งการโอนเงิน")])]),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" เลขที่ออเดอร์ "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"เลขที่ออเดอร์","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"readonly":"","color":"black","outlined":"","error-messages":errors,"dense":"","placeholder":"เลขที่ออเดอร์"},model:{value:(_vm.input.orderNumber),callback:function ($$v) {_vm.$set(_vm.input, "orderNumber", $$v)},expression:"input.orderNumber"}})]}}])})],1),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" จำนวนเงิน "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"จำนวนเงิน","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"readonly":"","color":"black","error-messages":errors,"outlined":"","dense":"","placeholder":"จำนวนเงิน"},model:{value:(_vm.input.amount),callback:function ($$v) {_vm.$set(_vm.input, "amount", $$v)},expression:"input.amount"}})]}}])})],1),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" ชื่อ "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"ชื่อ","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"color":"black","error-messages":errors,"outlined":"","dense":"","placeholder":"ชื่อ"},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}})]}}])})],1),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" อีเมล "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"อีเมล","rules":'required|email'},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"color":"black","error-messages":errors,"outlined":"","dense":"","placeholder":"อีเมล"},model:{value:(_vm.input.email),callback:function ($$v) {_vm.$set(_vm.input, "email", $$v)},expression:"input.email"}})]}}])})],1),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" เบอร์โทรศัพท์ "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"phone","rules":{
              required: true,
              min: 9,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"type":"number","color":"black","maxlength":"10","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.input.phone),callback:function ($$v) {_vm.$set(_vm.input, "phone", $$v)},expression:"input.phone"}})]}}])})],1),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" วันที่ "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"วันที่","rules":{
              required: true,
              min: 9,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('BirthdayPicker',{model:{value:(_vm.input.transferDate),callback:function ($$v) {_vm.$set(_vm.input, "transferDate", $$v)},expression:"input.transferDate"}},[_c('v-text-field',{attrs:{"value":_vm.input.transferDate
                    ? _vm.moment(_vm.input.transferDate)
                        .add(543, 'year')
                        .format('DD/MM/YYYY')
                    : '',"placeholder":"DD/MM/YYYY","readonly":"","outlined":"","dense":"","error-messages":errors}})],1)]}}])})],1),_c('div',[_c('label',{staticClass:"colabel"},[_vm._v(" เวลา "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"เวลา","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',[_c('vue-timepicker',{attrs:{"error-messages":errors,"input-class":"custom-picker"},model:{value:(_vm.input.transferTime),callback:function ($$v) {_vm.$set(_vm.input, "transferTime", $$v)},expression:"input.transferTime"}})],1)]}}])})],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"name":"Upload"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"colabel"},[_vm._v(" อัปโหลดสลิป "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('UploadDocument',{attrs:{"background":require(`@/assets/icon-upload.png`),"isProfileImage":false},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('custom-input',{attrs:{"loading":props.loading,"value":props.filename,"prepend-icon":"mdi-attachment","readonly":"","error-messages":errors}})]}}],null,true),model:{value:(_vm.input.slipImage),callback:function ($$v) {_vm.$set(_vm.input, "slipImage", $$v)},expression:"input.slipImage"}})]}}])})],1),_c('div',{staticClass:"mt-5"},[_c('label',{staticClass:"colabel"},[_vm._v("หมายเหตุ (ถ้ามี)")]),_c('v-textarea',{attrs:{"color":"black","outlined":"","dense":""},model:{value:(_vm.input.remark),callback:function ($$v) {_vm.$set(_vm.input, "remark", $$v)},expression:"input.remark"}})],1)])]),_c('div',{staticClass:"text-center mt-2"},[_c('v-btn',{staticStyle:{"font-size":"16px","color":"white","height":"48px"},attrs:{"loading":_vm.saveLoading,"dark":"","block":""},on:{"click":_vm.submitPayment}},[_vm._v("ยืนยัน")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }