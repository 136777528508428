<template>
  <div>
    <div class="text-xs-center">
      <v-container>
        <div style="margin-top: 20px;text-align: center">
          <v-icon style="font-size: 38px;color: black">mdi-cart-outline</v-icon>
        </div>
        <div
          style="font-family: 'Dosis', san-serif; font-weight: 600; font-size: 23px; text-align:center"
        >
          <div>Your cart is currently empty.</div>
        </div>
        <div style="margin-top: 25px;text-align: center">
          <v-btn @click="backToShop" color="black" style="color: white" rounded>BACK TO SHOP</v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  props: ["apptype"],
  methods: {
    backToShop() {
      var vm = this;
      vm.$router.push({
        path: 'all-products',
        query: {...vm.$route.query } ,
      }).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    exit() {
      var vm = this;
      if (vm.apptype == "line") {
        vm.$liff.closeWindow();
      } else if (vm.apptype == "facebook") {
        MessengerExtensions.requestCloseBrowser(
          function success() {},
          function error() {
            window.close();
          }
        );
      }
    }
  }
};
</script>

