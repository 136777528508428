<template>
  <div>
    <v-dialog width="500px" persistent v-model="showModal">
      <v-card class="pa-10">
        <div class="text-center">
          <div v-if="loading" style="text-align: center;">
            <v-progress-circular
              indeterminate
              color="black"
              size="50"
              :width="3"
            ></v-progress-circular>
          </div>

          <div v-else>
            <div v-if="paid">
              <h2 style="color: green">Payment success</h2>
              <div class="mt-4">
                <v-btn
                  @click="backToShop"
                  color="black"
                  style="color: white"
                  rounded
                  >BACK TO SHOP</v-btn
                >
              </div>
            </div>

            <div v-else>
              <h2>Payment pending</h2>
              <div class="mt-4">
                <v-btn
                  @click="fetchPayment"
                  color="black"
                  style="color: white"
                  rounded
                  >Check again</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Payment",
  data() {
    return {
      loading: true,
      paid: false,
      showModal: true,
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    this.fetchPayment();
  },

  methods: {
    async fetchPayment() {
      const vm = this;
      try {
        vm.loading = true;
        if (vm.$route.query) {
          const { order_id, pageid, userid } = vm.$route.query;
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}oab/v1/payment-status`,
            {
              headers: {
                Authorization: "Basic userid sessiontoken",
              },
              params: {
                order_id,
                page_id: pageid,
                user_id: userid,
              },
            }
          );
          if (response.status === 200) {
            if (response.data.paid) {
              vm.paid = true;
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        vm.loading = false;
      }
    },
    backToShop() {
      var vm = this;
      vm.$router
        .push({
          path: "all-products",
          query: { ...vm.$route.query },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
